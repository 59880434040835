import { Skeleton, Space, Tabs } from "antd";
import { isEmpty, sortBy, uniqBy } from "lodash";
import React, { PropsWithChildren, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import styled from "styled-components";

import { urls } from "src/urls";

import { FiltersRow, StatusIcon } from "../../../components";
import {
  CustomVehicleFieldValueStatus,
  ListCustomVehicleField,
  ListRetrieveCustomVehicleFieldsGroup,
  RetrieveVehicleCustomVehicleField,
} from "../../../models";
import { getResultingVehicleFieldsStatus } from "../../../utils";
import { BackOfficeLayout } from "../../BackOfficeLayout";
import { damagesContentMinWidth, DamagesList } from "../../DamagesList";
import { useAllCustomVehicleFieldsExcludingDamages } from "../hooks/useAllCustomVehicleFieldsExcludingDamages";
import { VehicleFieldValueHistoryModalView } from "../VehicleFieldValueHistoryModalView";
import { DisplayUpdateVehicleCustomFieldsForm } from "./index";

const ZeroMarginStatusIcon = styled(StatusIcon)`
  margin: 0 !important;
`;

function TabLabel(props: {
  status: CustomVehicleFieldValueStatus;
  text: string;
  testIdPrefix: string;
}): JSX.Element {
  return (
    <span data-testid={`${props.testIdPrefix}-${props.status}`}>
      <Space>
        <ZeroMarginStatusIcon status={props.status} />
        {props.text}
      </Space>
    </span>
  );
}

interface TabbedSectionProps {
  vehicleId: string;
  activeTab:
    | "customVehicleFields"
    | "damages"
    | ListRetrieveCustomVehicleFieldsGroup["id"];
  customVehicleFieldsStatus: CustomVehicleFieldValueStatus | null;
  damagesStatus: CustomVehicleFieldValueStatus;
  customVehicleFieldsGroups: ListRetrieveCustomVehicleFieldsGroup[];
  customVehicleFieldsGroupStatusById: Record<
    string,
    CustomVehicleFieldValueStatus
  >;
}

function TabbedSection(
  props: PropsWithChildren<TabbedSectionProps>
): JSX.Element {
  const { t } = useTranslation("backoffice");
  const history = useHistory();

  return (
    <>
      <Tabs
        size="large"
        tabBarStyle={{ fontWeight: 500 }}
        type="card"
        activeKey={props.activeTab}
        onTabClick={(clickedTab) => {
          switch (clickedTab) {
            case "customVehicleFields":
              history.push(urls.backOffice.vehicleCard(props.vehicleId));
              break;
            case "damages":
              history.push(urls.backOffice.vehicleCardDamages(props.vehicleId));
              break;
            default:
              history.push(
                urls.backOffice.vehicleCardCustomVehicleFieldsGroup(
                  props.vehicleId,
                  clickedTab
                )
              );
          }
        }}
        items={[
          ...(props.customVehicleFieldsStatus != null
            ? [
                {
                  label: (
                    <TabLabel
                      text={t("vehicleView.customVehicleFields")}
                      testIdPrefix="custom-vehicle-fields-status"
                      status={props.customVehicleFieldsStatus}
                    />
                  ),
                  key: "customVehicleFields",
                  children: null,
                },
              ]
            : []),
          {
            label: (
              <TabLabel
                text={t("damages.pageTitle")}
                testIdPrefix="damages-status"
                status={props.damagesStatus}
              />
            ),
            key: "damages",
            children: null,
          },
          ...props.customVehicleFieldsGroups.map(
            (customVehicleFieldsGroup) => ({
              label: (
                <TabLabel
                  text={customVehicleFieldsGroup.label}
                  testIdPrefix="custom-vehicle-fields-group-status"
                  status={
                    props.customVehicleFieldsGroupStatusById[
                      customVehicleFieldsGroup.id
                    ] || "none"
                  }
                />
              ),
              key: customVehicleFieldsGroup.id,
              children: null,
            })
          ),
        ]}
      />
      {props.children}
    </>
  );
}

interface Props {
  vehicleId: string;
  vehiclePlateNumber: string;
  vehicleTypeId: string;
  loadVehicleFieldValues: () => Promise<void>;
  vehicleCustomVehicleFields: Record<string, RetrieveVehicleCustomVehicleField>;
}

export function VehicleCardTabs(props: Props) {
  const history = useHistory();

  const {
    customVehicleFields: customVehicleFieldsExcludingDamages,
    isCustomVehicleFieldsLoading,
  } = useAllCustomVehicleFieldsExcludingDamages(props.vehicleTypeId);

  const customVehicleFieldsExcludingDamagesWithoutGroup = useMemo(
    () =>
      customVehicleFieldsExcludingDamages.filter(
        (customVehicleField) =>
          !customVehicleField.customVehicleFieldsGroupObject
      ),
    [customVehicleFieldsExcludingDamages]
  );

  const customVehicleFieldsWithGroupByGroupId = useMemo(
    () =>
      customVehicleFieldsExcludingDamages.reduce<
        Record<string, ListCustomVehicleField[]>
      >((previousValue, currentValue) => {
        if (!!currentValue.customVehicleFieldsGroupObject) {
          const previousValueArray =
            previousValue[currentValue.customVehicleFieldsGroupObject.id] || [];

          return {
            ...previousValue,
            [currentValue.customVehicleFieldsGroupObject.id]: [
              ...previousValueArray,
              currentValue,
            ],
          };
        }
        return previousValue;
      }, {}),
    [customVehicleFieldsExcludingDamages]
  );

  const customVehicleFieldsGroups = useMemo(
    () =>
      sortBy(
        uniqBy(
          Object.values(customVehicleFieldsExcludingDamages).reduce<
            ListRetrieveCustomVehicleFieldsGroup[]
          >(
            (previous, current) =>
              current.customVehicleFieldsGroupObject
                ? [...previous, current.customVehicleFieldsGroupObject]
                : previous,
            []
          ),
          "id"
        ),
        "order"
      ),
    [customVehicleFieldsExcludingDamages]
  );

  const damagesStatus = useMemo(
    () =>
      getResultingVehicleFieldsStatus(
        Object.values(props.vehicleCustomVehicleFields)
          .filter(
            (customFieldValue) =>
              customFieldValue.fieldObject.type === "damages"
          )
          .map((customFieldValue) => customFieldValue.valueObject.status)
      ),
    [props.vehicleCustomVehicleFields]
  );

  const customVehicleFieldsStatus = useMemo(
    () =>
      getResultingVehicleFieldsStatus(
        Object.values(props.vehicleCustomVehicleFields)
          .filter(
            (customFieldValue) =>
              customFieldValue.fieldObject.type !== "damages"
          )
          .filter(
            (vehicleCustomVehicleField) =>
              !vehicleCustomVehicleField.fieldObject
                .customVehicleFieldsGroupObject
          )
          .map((customFieldValue) => customFieldValue.valueObject.status)
      ),
    [props.vehicleCustomVehicleFields]
  );

  const customVehicleFieldsGroupStatusById = useMemo(
    () =>
      customVehicleFieldsGroups.reduce(
        (previousValue, currentValue) => ({
          ...previousValue,
          [currentValue.id]: getResultingVehicleFieldsStatus(
            Object.values(props.vehicleCustomVehicleFields)
              .filter(
                (customFieldValue) =>
                  customFieldValue.fieldObject.customVehicleFieldsGroupObject
                    ?.id === currentValue.id
              )
              .map((customFieldValue) => customFieldValue.valueObject.status)
          ),
        }),
        {}
      ),
    [customVehicleFieldsGroups, props.vehicleCustomVehicleFields]
  );

  const shouldDisplayVehicleFieldsTab =
    !!customVehicleFieldsExcludingDamagesWithoutGroup.length ||
    isEmpty(props.vehicleCustomVehicleFields);

  const commonTabbedSectionProps: Omit<TabbedSectionProps, "activeTab"> = {
    customVehicleFieldsGroups,
    vehicleId: props.vehicleId,
    customVehicleFieldsStatus: shouldDisplayVehicleFieldsTab
      ? customVehicleFieldsStatus
      : null,
    damagesStatus,
    customVehicleFieldsGroupStatusById,
  };

  return isCustomVehicleFieldsLoading ? (
    <Skeleton />
  ) : (
    <Switch>
      <Route path={urls.backOffice.vehicleCardDamages()} exact>
        <TabbedSection {...commonTabbedSectionProps} activeTab="damages">
          <DamagesList
            isCommonStateUpdateEnabled={false}
            constantFilters={{
              selectedVehicle: {
                label: "",
                value: props.vehicleId,
              },
              vehicleTypes: [],
            }}
            onSuccessfulStatusChange={props.loadVehicleFieldValues}
            onCreateButtonClick={() =>
              history.push(
                urls.backOffice.damages.create({
                  label: props.vehiclePlateNumber,
                  value: props.vehicleId,
                })
              )
            }
          >
            {({
              listElement,
              statusFiltersElement,
              customVehicleFieldElement,
            }) => (
              <div style={{ overflowX: "auto" }}>
                <div style={{ minWidth: damagesContentMinWidth }}>
                  <BackOfficeLayout.Filters>
                    <FiltersRow>
                      {statusFiltersElement}
                      {customVehicleFieldElement}
                    </FiltersRow>
                  </BackOfficeLayout.Filters>
                  {listElement}
                </div>
              </div>
            )}
          </DamagesList>
        </TabbedSection>
      </Route>
      {customVehicleFieldsGroups.map((customVehicleFieldsGroup) => {
        const customVehicleFieldsForGroup =
          customVehicleFieldsWithGroupByGroupId[customVehicleFieldsGroup.id] ||
          [];
        return (
          <Route
            path={urls.backOffice.vehicleCardCustomVehicleFieldsGroup(
              props.vehicleId,
              customVehicleFieldsGroup.id
            )}
            key={customVehicleFieldsGroup.id}
          >
            <TabbedSection
              {...commonTabbedSectionProps}
              activeTab={customVehicleFieldsGroup.id}
            >
              <DisplayUpdateVehicleCustomFieldsForm
                reloadVehicleFieldValues={props.loadVehicleFieldValues}
                customFieldValues={props.vehicleCustomVehicleFields}
                vehicleId={props.vehicleId}
                customVehicleFieldsToDisplay={customVehicleFieldsForGroup}
              />
              <Route
                path={urls.backOffice.vehicleFieldWithGroupValueHistory()}
                exact
              >
                <VehicleFieldValueHistoryModalView />
              </Route>
            </TabbedSection>
          </Route>
        );
      })}

      <Route path={urls.backOffice.vehicleCard()}>
        {shouldDisplayVehicleFieldsTab ? (
          <TabbedSection
            {...commonTabbedSectionProps}
            activeTab="customVehicleFields"
          >
            <DisplayUpdateVehicleCustomFieldsForm
              reloadVehicleFieldValues={props.loadVehicleFieldValues}
              customFieldValues={props.vehicleCustomVehicleFields}
              vehicleId={props.vehicleId}
              customVehicleFieldsToDisplay={
                customVehicleFieldsExcludingDamagesWithoutGroup
              }
            />
            <Route
              path={urls.backOffice.vehicleFieldWithoutGroupValueHistory()}
              exact
            >
              <VehicleFieldValueHistoryModalView />
            </Route>
          </TabbedSection>
        ) : (
          <Redirect to={urls.backOffice.vehicleCardDamages(props.vehicleId)} />
        )}
      </Route>

      <Route path="*" exact>
        <Redirect to={urls.backOffice.vehicleCard(props.vehicleId)} />
      </Route>
    </Switch>
  );
}
