import { CheckOutlined, WarningOutlined } from "@ant-design/icons";
import { Card, Col, Row, Skeleton, Statistic } from "antd";
import { isEmpty } from "lodash";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";

import { backOfficeApi } from "../../api";
import { Statistics } from "../../models";
import { BackOfficeLayout } from "../BackOfficeLayout";

const MaxWidthWrapper = styled.div`
  max-width: 700px;
  padding-bottom: 50px;
`;

const PrefixWrapper = styled.span`
  padding-right: 5px;
`;

function StatisticCard(props: {
  statistics: { label?: string; value: string | number }[];
  cardLabel?: string;
  icon?: ReactNode;
  precision?: number;
  color?: string;
  suffix?: string;
}) {
  return (
    <Col xs={24} sm={12}>
      <Card style={{ width: "100%" }} title={props.cardLabel}>
        {props.statistics.map((statistic) => (
          <Statistic
            key={`${props.cardLabel}${statistic.label}`}
            value={statistic.value}
            title={statistic.label}
            valueStyle={props.color ? { color: props.color } : undefined}
            precision={props.precision}
            prefix={
              <>{props.icon && <PrefixWrapper>{props.icon}</PrefixWrapper>}</>
            }
            suffix={props.suffix}
          />
        ))}
      </Card>
    </Col>
  );
}

export function Dashboard() {
  const { t } = useTranslation("backoffice");
  const [isLoading, setIsLoading] = useState(true);
  const [statisticValue, setStatisticValue] = useState<Statistics | null>(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const statisticsValue = await backOfficeApi.getStatistics();
      setStatisticValue(statisticsValue);
      setIsLoading(false);
    })();
  }, []);

  const theme = useTheme();

  const statistics: {
    label: string;
    icon?: ReactNode;
    fieldName: keyof Omit<
      Statistics,
      "vehicleTypeStatistics" | "unpaidCharges" | "paidCharges"
    >;
    precision?: number;
    color?: string;
    suffix?: string;
  }[] = [
    {
      label: t("statistics.positiveVehicles"),
      icon: <CheckOutlined />,
      fieldName: "positiveVehicles",
      color: theme.colors.status.ok,
      suffix: "%",
      precision: 2,
    },
    {
      label: t("statistics.numberOfProtocols"),
      fieldName: "numberOfProtocols",
    },
  ];
  return (
    <BackOfficeLayout pageTitle={t("dashboard")} contentMinWidth={0}>
      <BackOfficeLayout.Content>
        <MaxWidthWrapper>
          <Row gutter={[16, 16]}>
            {isLoading || !statisticValue ? (
              <Skeleton />
            ) : (
              <>
                {statistics.map((statistic) => (
                  <StatisticCard
                    key={statistic.fieldName}
                    cardLabel={statistic.label}
                    icon={statistic.icon}
                    statistics={[
                      { value: statisticValue[statistic.fieldName] },
                    ]}
                    precision={statistic.precision}
                    color={statistic.color}
                    suffix={statistic.suffix}
                  />
                ))}
                <StatisticCard
                  color={theme.colors.status.ok}
                  statistics={
                    isEmpty(statisticValue.paidCharges)
                      ? [{ value: "-" }]
                      : Object.entries(statisticValue.paidCharges)
                          .sort(([firstCurrency], [secondCurrency]) =>
                            firstCurrency.localeCompare(secondCurrency)
                          )
                          .map(([currency, amount]) => ({
                            value: `${amount} ${currency}`,
                          }))
                  }
                  cardLabel={t("statistics.paidCharges")}
                />
                <StatisticCard
                  color={theme.colors.status.notOk}
                  statistics={
                    isEmpty(statisticValue.unpaidCharges)
                      ? [{ value: "-" }]
                      : Object.entries(statisticValue.unpaidCharges)
                          .sort(([firstCurrency], [secondCurrency]) =>
                            firstCurrency.localeCompare(secondCurrency)
                          )
                          .map(([currency, amount]) => ({
                            value: `${amount} ${currency}`,
                          }))
                  }
                  cardLabel={t("statistics.unpaidCharges")}
                />
                <StatisticCard
                  statistics={statisticValue.vehicleTypeStatistics.map(
                    (vehicleTypeStatistic) => ({
                      label: vehicleTypeStatistic.label,
                      value: vehicleTypeStatistic.vehicles,
                    })
                  )}
                  cardLabel={t("statistics.numberOfVehicles")}
                />
                <StatisticCard
                  statistics={statisticValue.vehicleTypeStatistics.map(
                    (vehicleTypeStatistic) => ({
                      label: vehicleTypeStatistic.label,
                      value: vehicleTypeStatistic.negativeVehicles,
                    })
                  )}
                  cardLabel={t("statistics.numberOfNegativeVehicles")}
                  icon={<WarningOutlined />}
                  color={theme.colors.status.notOk}
                />
              </>
            )}
          </Row>
        </MaxWidthWrapper>
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
