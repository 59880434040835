import {
  Alert,
  Col,
  Divider,
  Empty,
  Grid,
  Row,
  Skeleton,
  Tag,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { zip } from "lodash";
import { Fragment, ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { SwitchInput } from "src/components/SwitchInput";
import { urls } from "src/urls";
import { formatToDateTime, fullName } from "src/utils";

import { FiltersRow, ProtocolResponse } from "../../components";
import { useProtocol } from "../../hooks";
import { Response } from "../../models";
import { BackOfficeLayout } from "../BackOfficeLayout";

interface ChildrenProps {
  children: ReactNode;
}

function StandardRow(props: ChildrenProps): JSX.Element {
  return <Row gutter={[24, 24]}>{props.children}</Row>;
}

function StandardCol(props: ChildrenProps): JSX.Element {
  return (
    <Col xs={24} md={12} xl={9} xxl={8}>
      {props.children}
    </Col>
  );
}

function ProtoATag(props: { children: string }): JSX.Element {
  return <Tag color="blue">{props.children}</Tag>;
}

function ProtoBTag(props: { children: string }): JSX.Element {
  return <Tag color="purple">{props.children}</Tag>;
}

export function CompareProtocolsView() {
  const breakpoint = Grid.useBreakpoint();
  const { t } = useTranslation("backoffice");
  const { firstProtocolId, secondProtocolId } = useParams<{
    firstProtocolId: string;
    secondProtocolId: string;
  }>();

  const [issuesOnly, setIssuesOnly] = useState(false);

  const [protocol01, isLoading01, fetchingFailedError01] =
    useProtocol(firstProtocolId);
  const [protocol02, isLoading02, fetchingFailedError02] =
    useProtocol(secondProtocolId);

  const isLoading = isLoading01 || isLoading02;
  const fetchingFailedError = [fetchingFailedError01, fetchingFailedError02]
    .filter((a) => a)
    .join(" ");

  const responses = useMemo(() => {
    const isAnyNotOk = (response01?: Response, response02?: Response) => {
      const isFirstOk = !response01 || response01.wasOk;
      const isSecondOk = !response02 || response02.wasOk;
      return !isFirstOk || !isSecondOk;
    };

    if (!protocol01 || !protocol02) {
      return [];
    }

    const orderedProtocol01Responses = protocol01.responseObjects.sort(
      (a, b) => {
        return a.order - b.order;
      }
    );

    const orderedProtocol02Responses = protocol02.responseObjects.sort(
      (a, b) => {
        return a.order - b.order;
      }
    );

    const zippedResponses = zip(
      orderedProtocol01Responses,
      orderedProtocol02Responses
    );

    return issuesOnly
      ? zippedResponses.filter(([response01, response02]) =>
          isAnyNotOk(response01, response02)
        )
      : zippedResponses;
  }, [issuesOnly, protocol01, protocol02]);

  return (
    <BackOfficeLayout
      pageTitle={t("compareProtocols.pageTitle")}
      breadcrumbs={[
        {
          label: t("protocolsView.pageTitle"),
          url: urls.backOffice.protocols(),
        },
      ]}
      contentMinWidth={0}
      headerDescription={
        <StandardRow>
          <StandardCol>
            <Typography.Paragraph>
              <ProtoATag>{t("compareProtocols.protocolA")}</ProtoATag>
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t("protocolLabel")}:{" "}
              <strong>{protocol01?.protocolObject.label}</strong>
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t("plateNumber")}:{" "}
              <strong>{protocol01?.vehicleObject.plateNumber}</strong>
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t("createdByName")}:{" "}
              <strong>
                {protocol01 ? fullName(protocol01.createdByObject) : ""}
              </strong>
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t("protocolCreatedAt")}:{" "}
              <strong>
                {formatToDateTime(dayjs(protocol01?.protocolObject.createdAt))}
              </strong>
            </Typography.Paragraph>
          </StandardCol>
          <StandardCol>
            <Typography.Paragraph>
              <ProtoBTag>{t("compareProtocols.protocolB")}</ProtoBTag>
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t("protocolLabel")}:{" "}
              <strong>{protocol02?.protocolObject.label}</strong>
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t("plateNumber")}:{" "}
              <strong>{protocol02?.vehicleObject.plateNumber}</strong>
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t("createdByName")}:{" "}
              <strong>
                {protocol02 ? fullName(protocol02.createdByObject) : ""}
              </strong>
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t("protocolCreatedAt")}:{" "}
              <strong>
                {formatToDateTime(dayjs(protocol02?.protocolObject.createdAt))}
              </strong>
            </Typography.Paragraph>
          </StandardCol>
        </StandardRow>
      }
    >
      <BackOfficeLayout.Content>
        <BackOfficeLayout.Filters>
          <FiltersRow>
            <SwitchInput
              isChecked={issuesOnly}
              label={t("issuesOnly")}
              onToggle={setIssuesOnly}
            />
          </FiltersRow>
        </BackOfficeLayout.Filters>
        <StandardRow>
          <StandardCol>{t("responsesList")}</StandardCol>
          {breakpoint.md && <StandardCol>{t("responsesList")}</StandardCol>}
        </StandardRow>
        <Divider style={{ marginTop: 12 }} />
        {fetchingFailedError.length ? (
          <Alert message={fetchingFailedError} type="error" />
        ) : isLoading ? (
          <Skeleton />
        ) : !responses.length ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t("protocolView.noResponsesMatchingFilters")}
          />
        ) : (
          responses.map((responses, index) => {
            return (
              <Fragment key={index}>
                <StandardRow>
                  {responses.map((response, index) => (
                    <StandardCol key={index}>
                      {!!response ? (
                        <>
                          <Row wrap={false}>
                            <Col flex="32px">
                              {index === 0 ? (
                                <ProtoATag>A</ProtoATag>
                              ) : (
                                <ProtoBTag>B</ProtoBTag>
                              )}
                            </Col>
                            <Col flex="auto">
                              <ProtocolResponse
                                response={response}
                                showStatusInLabel
                              />
                            </Col>
                          </Row>
                        </>
                      ) : null}
                    </StandardCol>
                  ))}
                </StandardRow>
                <Divider />
              </Fragment>
            );
          })
        )}
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
