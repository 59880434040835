import { useTranslation } from "react-i18next";

import { MultipleImagesImmediateUploader } from "../imageUploaders";
import { InputWrapper } from "../InputWrapper";
import { TextAreaInput } from "../TextAreaInput";
import { MultiChoiceOrChoiceFieldComponentProps } from "./MultiChoiceOrChoiceFieldComponent";
import {
  isChoiceQuestionFieldRequired,
  isChoiceResponsePositive,
} from "./selectors";

type PhotosAndNoteComponentProps = MultiChoiceOrChoiceFieldComponentProps;

export function PhotosAndNoteComponent(
  props: PhotosAndNoteComponentProps
): JSX.Element {
  const { t } = useTranslation();
  const selectedChoiceIds = props.value?.selectedChoiceIds || [];
  const isAnswerPositive = isChoiceResponsePositive(
    {
      isMultiChoice: !!props.isMultiChoice,
      choices: props.choices,
    },
    selectedChoiceIds
  );

  const photoFieldRequirement = isChoiceQuestionFieldRequired(
    props.photoRequirement,
    isAnswerPositive,
    !!selectedChoiceIds.length || !!props.isMultiChoice
  );

  const noteFieldRequirement = isChoiceQuestionFieldRequired(
    props.noteRequirement,
    isAnswerPositive,
    !!selectedChoiceIds.length || !!props.isMultiChoice
  );

  return (
    <>
      {!!photoFieldRequirement ? (
        <InputWrapper
          isRequired={photoFieldRequirement === "required"}
          label={t("addPhotos")}
        >
          <MultipleImagesImmediateUploader
            uploaderId={props.fileUploaderId}
            onChange={(images) => props.onChange?.({ photos: images })}
            value={props.value?.photos || []}
          />
        </InputWrapper>
      ) : null}
      {!!noteFieldRequirement ? (
        <TextAreaInput
          isRequired={noteFieldRequirement === "required"}
          value={props.value?.note || ""}
          onChange={(note) => props.onChange?.({ note: note })}
          label={t("addAComment")}
        />
      ) : null}
    </>
  );
}
