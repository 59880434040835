const paddings = {
  large: "30px",
  medium: "22px",
  small: "18px",
  xs: "12px",
  xss: "8px",
};

module.exports = {
  layoutBodyBackground: "#f0f2f5",
  primaryColor: "#00A3CC",
  primary1: "#e6f7ff",
  menuItemFontSize: "16px",
  menuDarkColor: "#fff",
  menuDarkItemActiveBg: "#3cbcdd",
  menuDarkItemHoverBg: "#007a99",
  menuItemBoundaryMargin: 0,
  menuItemVerticalMargin: 0,
  menuInlineToplevelItemHeight: "46px",
  menuItemHeight: "46px",
  animationDurationSlow: "0.1s",
  animationDurationBase: "50ms",
  animationDurationFast: "40ms",
  modalMaskBackground: "#000000d9",
  modalBodyPadding: paddings.large,
  resultIconFontSize: "58px",

  fontSizes: {
    base: "16px",
    small: "14px",
    large: "18px",
  },

  height: {
    base: "44px",
    small: "36px",
    large: "52px",
  },

  paddings: paddings,

  pagination: {
    item: {
      size: "38px",
    },
  },
};
