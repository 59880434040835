import {
  Alert,
  Badge,
  Col,
  Collapse,
  Empty,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ChargeEmployeeIconButton } from "src/components";
import { ImagesGallery } from "src/components/ImagesGallery";
import emptyPhotoImage from "src/static/empty-photo.svg";
import emptySignatureImage from "src/static/empty-signature.svg";
import { myTheme } from "src/theme";

import {
  ChargedEmployee,
  DamagesResponseDamage,
  isChoiceResponse,
  isDamagesResponse,
  isDateMonthResponse,
  isDateResponse,
  isNumberResponse,
  isPhotoActionResponse,
  isTextResponse,
  Response,
} from "../models";
import { CommonFieldProtocolResponse } from "./CommonFieldProtocolResponse";
import { ProtocolResponseLabelWithStatus } from "./ProtocolResponseLabelWithStatus";

const DamageAndButtonWrapper = styled.div`
  display: flex;
`;

const DamageTextWrapper = styled.div`
  flex: 1 1 100%;
`;

const DamageButtonWrapper = styled.div`
  flex: 0 0 50px;
  text-align: right;
`;

export function NoImageResponse(props: {
  text: string;
  icon: "signature" | "photo";
}): JSX.Element {
  return (
    <Empty
      style={{ display: "inline-block", margin: 0, color: "#7c7c7c" }}
      imageStyle={{ height: 80 }}
      image={props.icon === "signature" ? emptySignatureImage : emptyPhotoImage}
      description={props.text}
    />
  );
}

interface DamageResponseProps {
  damage: DamagesResponseDamage;
  onChargeEmployeeForDamageClick?: (damage: {
    id: string;
    label: string;
    photo: string | null;
    chargedEmployeeObject: ChargedEmployee | null;
  }) => void;
}

function DamageResponseCol({
  damage,
  ...props
}: DamageResponseProps): JSX.Element {
  const { t } = useTranslation("backoffice");
  const imagesGallery = (
    <ImagesGallery
      images={[
        {
          src: damage.damage.photo || "",
          alt: damage.damage.label,
        },
      ]}
      width="100%"
    />
  );
  return (
    <Col xs={24} key={damage.id}>
      <Row gutter={[16, 0]}>
        <Col xs={6}>
          {damage.isNewAddition ? (
            <Badge dot color={myTheme.colors.status.notOk}>
              {imagesGallery}
            </Badge>
          ) : (
            imagesGallery
          )}
        </Col>
        <Col xs={18}>
          <DamageAndButtonWrapper>
            <Space style={{ width: "100%" }} align="start">
              <DamageTextWrapper>
                {damage.damage.chargedEmployeeObject && (
                  <Tag
                    color={
                      ["paid_by_employee", "paid_by_insurance"].includes(
                        damage.damage.chargedEmployeeObject.status
                      )
                        ? "success"
                        : "error"
                    }
                  >
                    {t(
                      `chargedEmployeeStatus.shortName.${damage.damage.chargedEmployeeObject.status}`
                    )}
                  </Tag>
                )}
                <div style={{ marginTop: 4 }}>
                  <Typography.Text strong={damage.isNewAddition}>
                    {damage.damage.label}
                  </Typography.Text>
                </div>
              </DamageTextWrapper>
            </Space>
            {props.onChargeEmployeeForDamageClick ? (
              <DamageButtonWrapper>
                <ChargeEmployeeIconButton
                  onClick={() =>
                    props.onChargeEmployeeForDamageClick?.(damage.damage)
                  }
                />
              </DamageButtonWrapper>
            ) : null}
          </DamageAndButtonWrapper>
        </Col>
      </Row>
    </Col>
  );
}

interface Props
  extends Pick<DamageResponseProps, "onChargeEmployeeForDamageClick"> {
  showStatusInLabel?: boolean;
  response: Response;
}

export function ProtocolResponse({
  showStatusInLabel = false,
  ...props
}: Props): JSX.Element {
  const { t } = useTranslation("backoffice");

  let responseElement: ReactNode = null;
  if (
    isTextResponse(props.response) ||
    isDateResponse(props.response) ||
    isDateMonthResponse(props.response) ||
    isNumberResponse(props.response) ||
    isChoiceResponse(props.response) ||
    isPhotoActionResponse(props.response)
  ) {
    responseElement = (
      <CommonFieldProtocolResponse
        {...props.response}
        fieldLabel={props.response.label}
      />
    );
  } else if (isDamagesResponse(props.response)) {
    const newDamages = props.response.newDamages;
    const oldDamages = props.response.oldDamages;
    responseElement = (
      <>
        {newDamages.length || oldDamages.length ? (
          <div style={{ paddingTop: "16px" }}>
            <Collapse defaultActiveKey={["new"]}>
              {oldDamages.length && (
                <Collapse.Panel
                  header={`${t("protocolView.oldDamagesTitle")} (${
                    oldDamages.length
                  })`}
                  key="old"
                >
                  <Row gutter={[0, 10]}>
                    {oldDamages.map((damage) => (
                      <DamageResponseCol
                        key={damage.id}
                        damage={damage}
                        onChargeEmployeeForDamageClick={
                          props.onChargeEmployeeForDamageClick
                        }
                      />
                    ))}
                  </Row>
                </Collapse.Panel>
              )}
              {newDamages.length && (
                <Collapse.Panel
                  header={`${t("protocolView.newDamagesTitle")} (${
                    newDamages.length
                  })`}
                  key="new"
                >
                  <Row gutter={[0, 10]}>
                    {newDamages.map((damage) => (
                      <DamageResponseCol
                        key={damage.id}
                        damage={damage}
                        onChargeEmployeeForDamageClick={
                          props.onChargeEmployeeForDamageClick
                        }
                      />
                    ))}
                  </Row>
                </Collapse.Panel>
              )}
            </Collapse>
          </div>
        ) : null}
        {!newDamages.length && props.response.isRequired && (
          <Alert
            message={t("protocolView.noNewDamagesConfirmed")}
            type="success"
            showIcon
            style={{ marginTop: 10 }}
          />
        )}
      </>
    );
  }

  return (
    <ProtocolResponseLabelWithStatus
      label={props.response.label}
      status={
        showStatusInLabel ? (props.response.wasOk ? "ok" : "not_ok") : undefined
      }
    >
      {responseElement}
    </ProtocolResponseLabelWithStatus>
  );
}
