import { TranslatedField } from "src/locales";

import {
  ChoiceResponse,
  DateFieldValue,
  DateMonthFieldValue,
  NumberFieldValue,
  PhotoActionFieldValue,
} from "..";
import { ChoiceFieldValue } from "./ChoiceFieldComponent";

export enum RequirementType {
  Always = "always",
  Never = "never",
  Optional = "optional",
  OnNegative = "on_negative",
  OnPositive = "on_positive",
}

export interface PhotoNoteRequirement {
  photoRequired: RequirementType;
  noteRequired: RequirementType;
}

export interface PhotoNoteRequirementNever extends PhotoNoteRequirement {
  photoRequired: RequirementType.Never;
  noteRequired: RequirementType.Never;
}

export type TextFieldValue = string;

export type FieldValue =
  | TextFieldValue
  | DateFieldValue
  | NumberFieldValue
  | ChoiceFieldValue
  | PhotoActionFieldValue;

export type TextFieldType = "text";
export type NumberFieldType = "number";
export type DateFieldType = "date";
export type DateMonthFieldType = "date-month";
export type ChoiceFieldType = "choice";
export type PhotoActionFieldType = "photo-action";

export type FieldType =
  | TextFieldType
  | NumberFieldType
  | DateFieldType
  | DateMonthFieldType
  | ChoiceFieldType
  | PhotoActionFieldType;

export const fieldTypes: FieldType[] = [
  "text",
  "number",
  "date",
  "date-month",
  "choice",
  "photo-action",
];

export interface TextField extends PhotoNoteRequirementNever {
  type: TextFieldType;
  value: TextFieldValue;
}

export interface NumberField extends PhotoNoteRequirementNever {
  type: NumberFieldType;
  value: NumberFieldValue;
}

export interface DateField extends PhotoNoteRequirementNever {
  type: DateFieldType;
  value: DateFieldValue;
  suggestedValues: number[];
}

export interface DateMonthField extends PhotoNoteRequirementNever {
  type: DateMonthFieldType;
  value: DateMonthFieldValue;
}

export interface PhotoActionField extends PhotoNoteRequirementNever {
  type: PhotoActionFieldType;
  value: PhotoActionFieldValue;
}

interface _BaseChoiceFieldChoice {
  readonly label: string;
  labelTranslations: TranslatedField;
  isPositiveOrRequired: boolean;
}

export interface CreateUpdateChoiceFieldChoice extends _BaseChoiceFieldChoice {
  readonly frontendId: string;
  readonly id: string | null;
}

export interface RetrieveChoiceFieldChoice extends _BaseChoiceFieldChoice {
  readonly id: string;
}

export interface ChoiceField extends PhotoNoteRequirement {
  type: ChoiceFieldType;
  value: ChoiceFieldValue;
  choices: RetrieveChoiceFieldChoice[];
}

export type Field =
  | TextField
  | NumberField
  | DateMonthField
  | DateField
  | ChoiceField
  | PhotoActionField;

export interface FieldValueByType {
  text: TextFieldValue;
  number: NumberFieldValue;
  date: DateFieldValue;
  "date-month": DateMonthFieldValue;
  choice: ChoiceFieldValue;
  "photo-action": PhotoActionFieldValue;
}

export interface FieldValueForVerificationByType
  extends Omit<FieldValueByType, "choice"> {
  choice: ChoiceResponse;
}

export const emptyFieldValueByType: FieldValueByType = {
  text: "",
  date: null,
  "date-month": null,
  number: null,
  choice: null,
  "photo-action": null,
};

// TODO: FIGURE OUT PROPER NAMING AND CONSIDER REMOVING THAT OR THE ABOVE emptyFieldValueByType
export const emptyFieldValueForVerificationByType: FieldValueForVerificationByType =
  {
    ...emptyFieldValueByType,
    choice: {
      selectedChoiceIds: [],
      note: "",
      photos: [],
    },
  };
