import { Position, useCompany } from "@inspecto/common";
import { Button, Card, Col, Row, Skeleton, Space, Tag, Typography } from "antd";
import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Icon } from "src/components";
import { urls } from "src/urls";

import { backOfficeApi } from "../../api";
import {
  GoogleMapEmbedModal,
  labelTranslationKeyByCustomVehicleFieldStatus,
  ProtocolPosition,
  StatusIcon,
} from "../../components";
import {
  useVehicleFieldsPresets,
  useVehicleGroups,
  useVehicleTypes,
} from "../../hooks";
import {
  RetrieveVehicle,
  RetrieveVehicleCustomVehicleField,
} from "../../models";
import { getResultingVehicleFieldsStatus } from "../../utils";
import { BackOfficeLayout } from "../BackOfficeLayout";
import { UpdateVehicleFormModal, VehicleCardTabs } from "./components";

function InfoCardRow(props: PropsWithChildren<{}>): JSX.Element {
  return (
    <Card>
      <Row gutter={[24, 12]}>{props.children}</Row>
    </Card>
  );
}

function InfoCardCol(props: {
  label: string;
  content: ReactNode;
}): JSX.Element {
  return (
    <Col xs={24}>
      <Typography.Text type="secondary">{props.label}</Typography.Text>
      {props.content}
    </Col>
  );
}

export function VehicleCardView() {
  const { t } = useTranslation("backoffice");
  const company = useCompany();
  const [positionModalValue, setPositionModalValue] = useState(
    null as Position | null
  );
  const [isVehicleFormModalVisible, setIsVehicleFormModalVisible] =
    useState<boolean>(false);
  const { vehicleId } = useParams<{ vehicleId: string }>();
  const [isVehicleLoading, setIsVehicleLoading] = useState(true);
  const [vehicleCustomVehicleFields, setVehicleCustomVehicleFields] = useState<
    Record<string, RetrieveVehicleCustomVehicleField>
  >({});
  const [vehicle, setVehicle] = useState<RetrieveVehicle | null>(null);
  const { isLoadingVehicleGroups, vehicleGroupOptions } = useVehicleGroups();
  const { isLoadingVehicleFieldsPresets, vehicleFieldsPresetOptions } =
    useVehicleFieldsPresets();
  const { isLoadingVehicleTypes, vehicleTypeOptions } = useVehicleTypes();

  const loadVehicle = useCallback(async () => {
    setIsVehicleLoading(true);
    const vehicle = await backOfficeApi.getVehicle(vehicleId);
    setVehicle(vehicle);
    setIsVehicleLoading(false);
  }, [vehicleId]);

  const vehicleStatus = useMemo(
    () =>
      getResultingVehicleFieldsStatus(
        Object.values(vehicleCustomVehicleFields).map(
          (customVehicleField) => customVehicleField.valueObject.status
        )
      ),
    [vehicleCustomVehicleFields]
  );

  const loadVehicleFieldValues = useCallback(async () => {
    const vehicleCustomFields =
      await backOfficeApi.getVehicleCustomVehicleFields(vehicleId);

    const customVehicleFieldValuesByFieldId = vehicleCustomFields.reduce<
      Record<string, RetrieveVehicleCustomVehicleField>
    >((acc, curr) => {
      return {
        ...acc,
        [curr.fieldObject.id]: curr,
      };
    }, {});
    setVehicleCustomVehicleFields(customVehicleFieldValuesByFieldId);
  }, [vehicleId]);

  useEffect(() => {
    loadVehicle();
    loadVehicleFieldValues();
  }, [loadVehicle, loadVehicleFieldValues]);

  const currentVehicleType = vehicleTypeOptions.find(
    (option) => option.value === vehicle?.vehicleType
  );

  const currentVehicleGroups = vehicleGroupOptions.filter((option) =>
    vehicle?.vehicleGroups.includes(option.value)
  );
  const currentVehicleFieldsPreset = vehicleFieldsPresetOptions.find(
    (option) => vehicle?.vehicleFieldsPreset === option.value
  );

  return (
    <BackOfficeLayout
      contentMinWidth={0}
      breadcrumbs={[
        {
          label: t("vehiclesView.pageTitle"),
          url: urls.backOffice.vehicles(),
        },
      ]}
      pageTitle={t("vehicleCard")}
    >
      <BackOfficeLayout.Content>
        {isVehicleLoading ||
        isLoadingVehicleGroups ||
        isLoadingVehicleFieldsPresets ||
        isLoadingVehicleTypes ||
        !vehicle ? (
          <Skeleton />
        ) : (
          <>
            <Row gutter={[24, 12]} style={{ marginBottom: 40 }}>
              <Col xs={24} lg={12} xxl={10}>
                <InfoCardRow>
                  <Col xs={24}>
                    <Space
                      style={{
                        justifyContent: "space-between",
                        display: "flex",
                      }}
                    >
                      <Typography.Title level={5}>
                        {t("vehicleView.basicInformation")}
                      </Typography.Title>
                      <Button
                        shape="round"
                        size="small"
                        onClick={() => setIsVehicleFormModalVisible(true)}
                        title={`${t("vehicleView.customFields.edit")} - ${t(
                          "vehicleView.basicInformation"
                        )}`}
                      >
                        <Space>
                          <Icon icon="edit" />
                          {t("vehicleView.customFields.edit")}
                        </Space>
                      </Button>
                    </Space>
                  </Col>
                  <InfoCardCol
                    label={`${t("vehicleType")}: `}
                    content={
                      <Typography.Text strong>
                        {currentVehicleType ? currentVehicleType.label : "-"}
                      </Typography.Text>
                    }
                  />
                  <InfoCardCol
                    label={`${t("plateNumber")}: `}
                    content={
                      <Typography.Text strong>
                        {vehicle.plateNumber}
                      </Typography.Text>
                    }
                  />
                  {company.allowManagingCompanyDepartments && (
                    <InfoCardCol
                      label={`${t(
                        "settings.companyDepartments.singleItemLabel"
                      )}: `}
                      content={<Tag>{vehicle.companyDepartment.name}</Tag>}
                    />
                  )}
                  <InfoCardCol
                    label={`${t("vehicleGroups")}: `}
                    content={
                      currentVehicleGroups.length
                        ? currentVehicleGroups.map((vehicleGroup) => (
                            <Tag>
                              <a
                                href={urls.backOffice.settings.editVehicleGroup(
                                  vehicleGroup.value
                                )}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {vehicleGroup.label}
                              </a>
                            </Tag>
                          ))
                        : "-"
                    }
                  />
                  <InfoCardCol
                    label={`${t("vehicleView.vehicleFieldsPreset")}: `}
                    content={
                      !!currentVehicleFieldsPreset ? (
                        <Tag>
                          <a
                            href={urls.backOffice.settings.editVehicleFieldsPreset(
                              currentVehicleFieldsPreset.value
                            )}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {currentVehicleFieldsPreset.label}
                          </a>
                        </Tag>
                      ) : (
                        "-"
                      )
                    }
                  />
                </InfoCardRow>
              </Col>
              <Col xs={24} lg={12} xxl={9}>
                <InfoCardRow>
                  <Col xs={24}>
                    <Space size="small">
                      <Typography.Text type="secondary">
                        {t("status")}
                        {":"}
                      </Typography.Text>
                      <StatusIcon status={vehicleStatus} />
                      <Typography.Text
                        strong
                        data-testid={`vehicle-status-${vehicleStatus}`}
                      >
                        {t(
                          `fleetState.statuses.${labelTranslationKeyByCustomVehicleFieldStatus[vehicleStatus]}`
                        )}
                      </Typography.Text>
                    </Space>
                  </Col>
                  <Col xs={24}>
                    <div style={{ paddingBottom: 5 }}>
                      <Typography.Text type="secondary">
                        {t("vehicleView.lastProtocolCreationLocation")}
                      </Typography.Text>
                    </div>
                    <ProtocolPosition
                      displayDate
                      positionObject={vehicle.positionObject || null}
                      onPositionClick={(position) =>
                        setPositionModalValue(position)
                      }
                    />
                  </Col>
                </InfoCardRow>
              </Col>
            </Row>

            <VehicleCardTabs
              vehicleId={vehicleId}
              vehiclePlateNumber={vehicle.plateNumber}
              vehicleTypeId={vehicle.vehicleType}
              loadVehicleFieldValues={loadVehicleFieldValues}
              vehicleCustomVehicleFields={vehicleCustomVehicleFields}
            />

            {positionModalValue && (
              <GoogleMapEmbedModal
                position={positionModalValue}
                onClose={() => setPositionModalValue(null)}
              />
            )}
            {isVehicleFormModalVisible && (
              <UpdateVehicleFormModal
                currentVehicleValue={vehicle}
                reloadVehicleAndFieldValues={() => {
                  loadVehicle();
                  loadVehicleFieldValues();
                }}
                vehicleGroupsOptions={vehicleGroupOptions}
                vehicleFieldsPresetOptions={vehicleFieldsPresetOptions}
                vehicleTypeOptions={vehicleTypeOptions}
                closeModal={() => setIsVehicleFormModalVisible(false)}
              />
            )}
          </>
        )}
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
