import {
  CopyOutlined,
  EllipsisOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import { Position } from "@inspecto/common";
import {
  Alert,
  Button,
  Descriptions,
  Divider,
  Dropdown,
  Empty,
  Grid,
  List,
  message,
  Modal,
  Skeleton,
  Space,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { identity } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import { useAuthentication } from "src/authentication";
import { Icon, UserAvatar } from "src/components";
import { SwitchInput } from "src/components/SwitchInput";
import { useReporting } from "src/reporting/useReporting";
import { urls } from "src/urls";
import { formatToDateTime, fullName } from "src/utils";

import { backOfficeApi } from "../../api";
import {
  AddEditDeleteCommentModal,
  DownloadProtocolPdfModal,
  FillOutSignatureModal,
  FiltersRow,
  GoogleMapEmbedModal,
  ProtocolPosition,
  ProtocolResponse,
  ProtocolResponseActions,
  ProtocolResponseRow,
  ProtocolSignature,
  ProtocolSignatureActions,
  RestrictedLink,
  SendProtocolPdfViaEmailModal,
} from "../../components";
import { useChargedEmployeeModal, useProtocol } from "../../hooks";
import {
  EmailLogStatus,
  Response,
  RetrieveProtocolEmailLog,
  RetrieveProtocolSignature,
} from "../../models";
import { getResponseTextRepresentation } from "../../utils";
import { BackOfficeLayout } from "../BackOfficeLayout";

interface CommentModalState {
  responseId: string;
  commentId: string | null;
  commentText: string;
}

export function ProtocolView() {
  const { hasPerm } = useAuthentication();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;
  const { t } = useTranslation("backoffice");
  const { protocolId } = useParams<{ protocolId: string }>();
  const { logAnalyticsEvent } = useReporting();

  const [issuesOnly, setIssuesOnly] = useState(false);
  const [
    isSendProtocolPdfViaEmailModalVisible,
    setSendProtocolPdfViaEmailModalVisible,
  ] = useState(false);

  const [
    isDownloadProtocolPdfModalVisible,
    setIsDownloadProtocolPdfModalVisible,
  ] = useState(false);
  const [isSentEmailsHistoryModalVisible, setIsSentEmailsHistoryModalVisible] =
    useState(false);
  const [commentModalState, setCommentModalState] =
    useState<CommentModalState | null>(null);
  const [chargedEmployeeModal, setChargedEmployeeState] =
    useChargedEmployeeModal(() => refreshProtocol(false));
  const [fillOutSignatureState, setFillOutSignatureState] =
    useState<RetrieveProtocolSignature | null>(null);

  const [positionModalValue, setPositionModalValue] = useState(
    null as Position | null
  );

  const [protocol, isLoading, fetchingFailedError, refreshProtocol] =
    useProtocol(protocolId);

  const lastSuccessfulEmailLog: RetrieveProtocolEmailLog | undefined =
    useMemo(() => {
      if (!protocol) {
        return;
      }

      return protocol.emailLogs
        .filter(
          (emailLog) => emailLog.status === EmailLogStatus.SUCCESSFULLY_SENT
        )
        .reverse()
        .find(identity);
    }, [protocol]);

  const responses = useMemo(() => {
    const responseFilter = (response: Response) => {
      if (issuesOnly) {
        return !response.wasOk;
      } else {
        return true;
      }
    };

    if (!protocol) {
      return [];
    }

    return protocol.responseObjects.filter(responseFilter).sort((a, b) => {
      return a.order - b.order;
    });
  }, [issuesOnly, protocol]);

  const toggleResponse = useCallback(
    async (responseId: string) => {
      await backOfficeApi.toggleResponse(protocolId, responseId);

      await refreshProtocol(false);
    },
    [refreshProtocol, protocolId]
  );
  const applyResponseValue = useCallback(
    async (responseId: string) => {
      await backOfficeApi.applyResponseValue(protocolId, responseId);

      await refreshProtocol(false);
    },
    [refreshProtocol, protocolId]
  );

  const copyResponsesToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(
        responses
          .map(getResponseTextRepresentation)
          .filter((a) => a)
          .map((responseValue, index) => `${index + 1}. ${responseValue}`)
          .join("\n")
      );
      message.success(t("protocolView.responsesHaveBeenCopiedToClipboard"));
    } catch (err) {
      message.error(t("somethingWentWrong"));
    }
  }, [responses, t]);

  return (
    <BackOfficeLayout
      pageTitle={t("protocol")}
      breadcrumbs={[
        {
          label: t("protocolsView.pageTitle"),
          url: urls.backOffice.protocols(),
        },
      ]}
      pageHeaderExtra={[
        <Button onClick={() => setIsDownloadProtocolPdfModalVisible(true)}>
          <Space size="small">
            <Icon icon="arrowAltCircleDown" />
            {t("actions.downloadPdf")}
          </Space>
        </Button>,
        ...(hasPerm("backoffice.protocols.write")
          ? [
              <Button
                onClick={() => setSendProtocolPdfViaEmailModalVisible(true)}
              >
                <Space size="small">
                  <Icon icon="envelope" />
                  {t("actions.sendPdfViaEmail")}
                </Space>
              </Button>,
            ]
          : []),
        <Dropdown
          key="actions-dropdown"
          placement="bottomRight"
          trigger={["click"]}
          menu={{
            items: [
              {
                key: "copy-responses",
                label: t("protocolView.copyResponsesToClipboard"),
                icon: <CopyOutlined />,
                onClick: copyResponsesToClipboard,
              },
            ],
          }}
        >
          <Button
            icon={<EllipsisOutlined />}
            data-testid="more-protocol-actions-dropdown-trigger"
          />
        </Dropdown>,
      ]}
      contentMinWidth={0}
      headerDescription={
        isLoading || !protocol ? (
          <Skeleton />
        ) : (
          <Descriptions column={isMobile ? 1 : 2}>
            <Descriptions.Item label={t("protocolLabel")}>
              <Typography.Text strong>
                {protocol.protocolObject.label}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label={t("plateNumber")}>
              <Typography.Text strong>
                <RestrictedLink
                  requiredPermission="backoffice.vehicles.read"
                  to={urls.backOffice.vehicleCard(protocol.vehicleObject.id)}
                >
                  {protocol.vehicleObject.plateNumber}
                </RestrictedLink>
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label={t("createdByName")}>
              <Typography.Text strong>
                <RestrictedLink
                  requiredPermission="backoffice.employees.write"
                  to={urls.backOffice.editEmployee(protocol.createdByObject.id)}
                >
                  {protocol ? fullName(protocol.createdByObject) : ""}
                </RestrictedLink>
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label={t("protocolCreatedAt")}>
              <Typography.Text strong>
                {formatToDateTime(dayjs(protocol.protocolObject.createdAt))}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item
              label={t("protocolView.protocolCreatedInPosition")}
            >
              <ProtocolPosition
                positionObject={protocol.createdInPositionObject || null}
                onPositionClick={(position) => setPositionModalValue(position)}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={t("protocolView.protocolLastSentByEmail")}
            >
              {!!lastSuccessfulEmailLog ? (
                <Space style={{ alignItems: "start" }}>
                  <Icon icon="envelope" />
                  <div>
                    <div>
                      <Typography.Text strong>
                        {formatToDateTime(
                          dayjs(lastSuccessfulEmailLog.createdAt)
                        )}
                      </Typography.Text>
                    </div>
                    <div>
                      do{" "}
                      <Typography.Text strong>
                        {lastSuccessfulEmailLog.recipientEmail}
                      </Typography.Text>
                    </div>

                    <div style={{ paddingTop: 10 }}>
                      <Typography.Text strong>
                        <Link
                          to="#"
                          onClick={() =>
                            setIsSentEmailsHistoryModalVisible(true)
                          }
                        >
                          <Space>
                            <HistoryOutlined />
                            {t("emails.historyOfSentEmails")}
                          </Space>
                        </Link>
                      </Typography.Text>
                    </div>
                  </div>
                </Space>
              ) : (
                <Typography.Text strong>-</Typography.Text>
              )}
            </Descriptions.Item>
          </Descriptions>
        )
      }
    >
      <BackOfficeLayout.Content>
        <BackOfficeLayout.Filters>
          <FiltersRow>
            <SwitchInput
              isChecked={issuesOnly}
              label={t("issuesOnly")}
              onToggle={setIssuesOnly}
            />
          </FiltersRow>
        </BackOfficeLayout.Filters>
        <div>{t("responsesList")}</div>
        <Divider style={{ marginTop: 12 }} />
        {fetchingFailedError.length ? (
          <Alert message={fetchingFailedError} type="error" />
        ) : isLoading || !protocol ? (
          <Skeleton />
        ) : !responses.length && !protocol.signatureObjects.length ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t("protocolView.noResponsesMatchingFilters")}
          />
        ) : (
          <>
            {responses.map((response) => {
              return (
                <ProtocolResponseRow
                  key={response.id}
                  leftContent={
                    <ProtocolResponse
                      response={response}
                      onChargeEmployeeForDamageClick={
                        hasPerm("backoffice.charged_employees.write")
                          ? (damage) => {
                              const chargedEmployee =
                                damage.chargedEmployeeObject;
                              setChargedEmployeeState({
                                damageId: damage.id,
                                chargedEmployeeId: chargedEmployee?.id || null,
                                initialValues: chargedEmployee
                                  ? { ...chargedEmployee }
                                  : {
                                      comment: damage ? damage.label : "",
                                    },
                              });
                            }
                          : undefined
                      }
                    />
                  }
                  rightContent={
                    <ProtocolResponseActions
                      addCommentTooltipText={
                        response.comment
                          ? t("protocolView.editComment")
                          : t("protocolView.addComment")
                      }
                      applyValueTooltipText={t(
                        "protocolView.applyValueToVehicle"
                      )}
                      onApplyValueClick={
                        response.customVehicleFieldId &&
                        hasPerm("backoffice.vehicles.write")
                          ? () => {
                              Modal.confirm({
                                title: t("protocolView.applyValue.areYouSure"),
                                centered: true,
                                closable: true,
                                maskClosable: true,
                                okText: t("protocolView.applyValue.okText"),
                                onOk: async () => {
                                  await applyResponseValue(response.id);
                                  logAnalyticsEvent({
                                    eventType:
                                      "appliedValueFromProtocolToVehicle",
                                    payload: {},
                                  });
                                },
                              });
                            }
                          : undefined
                      }
                      onAddCommentClick={
                        hasPerm("backoffice.protocols.write")
                          ? () =>
                              setCommentModalState({
                                responseId: response.id,
                                commentId: response.comment
                                  ? response.comment.id
                                  : null,
                                commentText: response.comment
                                  ? response.comment.text
                                  : "",
                              })
                          : undefined
                      }
                      onChargeEmployeeClick={
                        response.type !== "damages" &&
                        hasPerm("backoffice.charged_employees.write")
                          ? () =>
                              setChargedEmployeeState({
                                responseId: response.id,
                                historicalCustomVehicleFieldValueId:
                                  response.historicalCustomVehicleFieldValueId ||
                                  undefined,
                                initialValues: response.chargedEmployee
                                  ? { ...response.chargedEmployee }
                                  : undefined,
                                chargedEmployeeId: response.chargedEmployee
                                  ? response.chargedEmployee.id
                                  : null,
                              })
                          : undefined
                      }
                      onStatusClick={
                        hasPerm("backoffice.protocols.write")
                          ? async () => await toggleResponse(response.id)
                          : undefined
                      }
                      wasOk={response.wasOk}
                      okOverriddenBy={response.okOverriddenBy}
                      okOverriddenAt={response.okOverriddenAt}
                      comment={response.comment}
                      chargedEmployee={response.chargedEmployee}
                    />
                  }
                />
              );
            })}
            {!issuesOnly &&
              protocol.signatureObjects.map((signature) => (
                <ProtocolResponseRow
                  leftContent={<ProtocolSignature signature={signature} />}
                  rightContent={
                    hasPerm("backoffice.protocols.write") ? (
                      <ProtocolSignatureActions
                        signature={signature}
                        onFillOutSignatureButtonClick={() =>
                          setFillOutSignatureState(signature)
                        }
                      />
                    ) : undefined
                  }
                />
              ))}
          </>
        )}
        {!!commentModalState &&
          (commentModalState.commentId ? (
            <AddEditDeleteCommentModal
              title={t("protocolView.editComment")}
              cancelButtonText={t("protocolView.cancelAddingComment")}
              actionButtonText={t("protocolView.saveComment")}
              unexpectedErrorMessage={t(
                "protocolView.somethingWentWrongWhenEditingComment"
              )}
              unexpectedErrorMessageOnDestroy={t(
                "protocolView.somethingWentWrongWhenDeletingComment"
              )}
              initialCommentText={commentModalState.commentText}
              performAction={async (text) => {
                if (!commentModalState.commentId) {
                  return;
                }
                await backOfficeApi.editResponseComment(
                  commentModalState.commentId,
                  {
                    text,
                  }
                );
                await refreshProtocol(false);
              }}
              removeObject={async () => {
                if (!commentModalState.commentId) {
                  return;
                }
                await backOfficeApi.removeResponseComment(
                  commentModalState.commentId
                );
                await refreshProtocol(false);
              }}
              destroyButtonText={t("protocolView.removeComment")}
              closeModal={() => setCommentModalState(null)}
            />
          ) : (
            <AddEditDeleteCommentModal
              title={t("protocolView.addComment")}
              cancelButtonText={t("protocolView.cancelAddingComment")}
              actionButtonText={t("protocolView.saveComment")}
              destroyButtonText={t("protocolView.removeComment")}
              unexpectedErrorMessage={t(
                "protocolView.somethingWentWrongWhenAddingComment"
              )}
              unexpectedErrorMessageOnDestroy={t(
                "protocolView.somethingWentWrongWhenDeletingComment"
              )}
              performAction={async (text) => {
                await backOfficeApi.addResponseComment(
                  commentModalState.responseId,
                  {
                    text,
                  }
                );
                await refreshProtocol(false);
              }}
              closeModal={() => setCommentModalState(null)}
            />
          ))}
        {chargedEmployeeModal}
        {!!fillOutSignatureState && (
          <FillOutSignatureModal
            signature={fillOutSignatureState}
            closeModal={() => setFillOutSignatureState(null)}
            saveSignature={async (payload) => {
              if (!fillOutSignatureState) {
                return;
              }
              await backOfficeApi.editSignature(
                fillOutSignatureState.id,
                payload
              );
              refreshProtocol(false);
            }}
          />
        )}
        {isSendProtocolPdfViaEmailModalVisible && protocol && (
          <SendProtocolPdfViaEmailModal
            onSuccess={(data) => {
              refreshProtocol();
              Modal.success({
                closable: true,
                width: 460,
                title: t("protocolView.sendPdfViaEmailSuccessModal.title"),
                content: (
                  <>
                    <div>
                      <Typography.Link
                        href={data.pdfProtocolUrl}
                        target="_blank"
                      >
                        {t(
                          "protocolView.sendPdfViaEmailSuccessModal.previewSentPDFLink"
                        )}
                      </Typography.Link>
                    </div>
                    <Typography.Text type="secondary">
                      {t(
                        "protocolView.sendPdfViaEmailSuccessModal.linkExpirationInfo",
                        {
                          date: data.pdfProtocolUrlExpirationDate,
                        }
                      )}
                    </Typography.Text>
                  </>
                ),
              });
            }}
            protocol={protocol}
            closeModal={() => setSendProtocolPdfViaEmailModalVisible(false)}
          />
        )}

        {isDownloadProtocolPdfModalVisible && protocol && (
          <DownloadProtocolPdfModal
            protocolId={protocol.protocolObject.id}
            closeModal={() => setIsDownloadProtocolPdfModalVisible(false)}
          />
        )}

        {positionModalValue && (
          <GoogleMapEmbedModal
            position={positionModalValue}
            onClose={() => setPositionModalValue(null)}
          />
        )}

        {isSentEmailsHistoryModalVisible && protocol && (
          <Modal
            open
            onCancel={() => setIsSentEmailsHistoryModalVisible(false)}
            title={t("emails.historyOfSentEmails")}
            okButtonProps={{ style: { display: "none" } }}
            width={650}
          >
            <List
              itemLayout="horizontal"
              dataSource={protocol.emailLogs}
              renderItem={(emailLog) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <UserAvatar
                        user={emailLog.triggeredByObject}
                        style={{ marginRight: -10 }}
                      />
                    }
                    title={`${fullName(
                      emailLog.triggeredByObject
                    )}, ${formatToDateTime(dayjs(emailLog.createdAt))}`}
                    description={
                      <>
                        <div>
                          {emailLog.status ===
                            EmailLogStatus.FAILED_TO_SEND && (
                            <Alert
                              message={t("emails.sendingStatus.failedToSend")}
                              type="error"
                              showIcon
                              style={{
                                margin: "10px 0px",
                                padding: "6px 12px",
                              }}
                            />
                          )}
                          {`${t("emails.recipient")}: `}
                          <Typography.Text strong>
                            {emailLog.recipientEmail}
                          </Typography.Text>
                        </div>
                        {!!emailLog.ccEmails.length && (
                          <div>
                            {`${t("sendProtocolPdfViaEmailModal.CC")}: `}
                            <Typography.Text strong type="secondary">
                              {emailLog.ccEmails.join("; ")}
                            </Typography.Text>
                          </div>
                        )}
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </Modal>
        )}
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
