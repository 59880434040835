import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";
import { Col, Row, Space, Typography } from "antd";
import React, { ReactNode } from "react";
import { CSSProperties } from "styled-components";

import { myTheme } from "src/theme";

import { CustomVehicleFieldValueStatus } from "../models";

const statusIconHeight = 16;
const statusIconStyle: CSSProperties = {
  fontSize: statusIconHeight,
  height: statusIconHeight,
};

export function StatusIcon(props: {
  status: CustomVehicleFieldValueStatus;
  className?: string;
}): JSX.Element {
  const commonProps = {
    className: props.className,
    style: statusIconStyle,
  };
  switch (props.status) {
    case "ok":
      return (
        <CheckCircleTwoTone
          {...commonProps}
          twoToneColor={myTheme.colors.status.ok}
        />
      );
    case "not_ok":
      return (
        <CloseCircleTwoTone
          {...commonProps}
          twoToneColor={myTheme.colors.status.notOk}
        />
      );
    case "warning":
      return (
        <ExclamationCircleTwoTone {...commonProps} twoToneColor="orange" />
      );
    case "none":
      return <></>;
  }
}

interface Props {
  label: string;
  status?: CustomVehicleFieldValueStatus;
  children: ReactNode;
}

export function ProtocolResponseLabelWithStatus(props: Props): JSX.Element {
  const labelElement = <Typography.Text strong>{props.label}</Typography.Text>;
  return (
    <Row gutter={[16, 6]}>
      <Col xs={24}>
        {!!props.status ? (
          <Space align="start">
            <StatusIcon status={props.status} />
            {labelElement}
          </Space>
        ) : (
          labelElement
        )}
      </Col>
      <Col xs={24}>{props.children}</Col>
    </Row>
  );
}
