import { useCompany } from "@inspecto/common";
import { createContext, PropsWithChildren, useContext } from "react";

interface ContextValue {
  preventPhotoUploadFromFileBrowser: boolean;
}

const defaultValue: ContextValue = {
  preventPhotoUploadFromFileBrowser: false,
};

export const preventPhotoUploadFromFileBrowserContext =
  createContext<ContextValue>(defaultValue);

export function PreventPhotoUploadFromFileBrowserContextProvider(
  props: PropsWithChildren<{}>
): JSX.Element {
  const { allowAddingPhotosOnDesktopInProtocolFiller } = useCompany();
  return (
    <preventPhotoUploadFromFileBrowserContext.Provider
      value={{
        preventPhotoUploadFromFileBrowser:
          !allowAddingPhotosOnDesktopInProtocolFiller,
      }}
    >
      {props.children}
    </preventPhotoUploadFromFileBrowserContext.Provider>
  );
}

export const usePreventPhotoUploadFromFileBrowserContext = () =>
  useContext(preventPhotoUploadFromFileBrowserContext);
