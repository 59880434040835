import { StorageUploadedFile } from "src/storage";

import {
  CommonImmediateImageUploader,
  ImmediateImageUploaderProps,
} from "./CommonImmediateImageUploader";

export type PhotoActionFieldValue = StorageUploadedFile | null;

export interface SingleImageImmediateUploaderProps
  extends Pick<ImmediateImageUploaderProps, "uploaderId"> {
  value?: PhotoActionFieldValue;
  onChange?(image: PhotoActionFieldValue): void;
}

export function SingleImageImmediateUploader({
  value,
  onChange,
  ...props
}: SingleImageImmediateUploaderProps) {
  return (
    <CommonImmediateImageUploader
      singleImageMode={true}
      onChange={(uploadedImages) =>
        onChange
          ? onChange(uploadedImages.length ? uploadedImages[0]! : null)
          : null
      }
      value={value ? [value] : undefined}
      {...props}
    />
  );
}
