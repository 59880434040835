import styled from "styled-components";

import { StorageUploadedFile } from "src/storage";

import { RequirementType } from "..";
import { ChoiceSelectionButton } from "../ChoiceSelectionButton";
import { ChoiceFieldComponent } from "./ChoiceFieldComponent";
import {
  isChoiceQuestionFieldRequired,
  isChoiceResponsePositive,
} from "./selectors";

const QuestionChoices = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
`;

export interface ChoiceResponse {
  selectedChoiceIds: string[];
  note: string;
  photos: StorageUploadedFile[];
}

interface Choice {
  id: string;
  label: string;
  isPositiveOrRequired: boolean;
}

export interface MultiChoiceOrChoiceFieldComponentProps {
  fileUploaderId: string;
  isMultiChoice?: boolean;
  choices: Choice[];
  photoRequirement: RequirementType;
  noteRequirement: RequirementType;
  onChange?(response: Partial<ChoiceResponse>): void;
  value?: ChoiceResponse;
}

export function MultiChoiceOrChoiceFieldComponent(
  props: MultiChoiceOrChoiceFieldComponentProps
) {
  return (
    <>
      {props.isMultiChoice ? (
        <QuestionChoices>
          {props.choices.map((choice) => (
            <ChoiceSelectionButton
              key={choice.id}
              onClick={() => setSelectedChoiceIds(choice.id)}
              isSelected={!!props.value?.selectedChoiceIds.includes(choice.id)}
              label={choice.label}
            />
          ))}
        </QuestionChoices>
      ) : (
        <ChoiceFieldComponent
          choices={props.choices.map((choice) => ({
            label: choice.label,
            value: choice.id,
          }))}
          value={
            props.value?.selectedChoiceIds.length
              ? props.value?.selectedChoiceIds[0]
              : null
          }
          onChange={setSelectedChoiceIds}
        />
      )}
    </>
  );

  function setSelectedChoiceIds(selectedChoiceId: string | null) {
    let newSelectedChoiceIds: ChoiceResponse["selectedChoiceIds"] = [
      ...(props.value?.selectedChoiceIds || []),
    ];

    if (selectedChoiceId == null) {
      newSelectedChoiceIds = [];
    } else if (props.isMultiChoice) {
      if (newSelectedChoiceIds.includes(selectedChoiceId)) {
        newSelectedChoiceIds = newSelectedChoiceIds.filter(
          (choice) => choice !== selectedChoiceId
        );
      } else {
        newSelectedChoiceIds = [...newSelectedChoiceIds, selectedChoiceId];
      }
    } else {
      if (newSelectedChoiceIds.includes(selectedChoiceId)) {
        newSelectedChoiceIds = [];
      } else {
        newSelectedChoiceIds = [selectedChoiceId];
      }
    }

    const isNewAnswerPositive = isChoiceResponsePositive(
      {
        isMultiChoice: !!props.isMultiChoice,
        choices: props.choices,
      },
      newSelectedChoiceIds
    );

    const newChoiceResponse: Partial<ChoiceResponse> = {
      selectedChoiceIds: newSelectedChoiceIds,
    };

    if (
      !isChoiceQuestionFieldRequired(
        props.photoRequirement,
        isNewAnswerPositive,
        !!newSelectedChoiceIds.length
      )
    ) {
      newChoiceResponse.photos = [];
    }

    if (
      !isChoiceQuestionFieldRequired(
        props.noteRequirement,
        isNewAnswerPositive,
        !!newSelectedChoiceIds.length
      )
    ) {
      newChoiceResponse.note = "";
    }

    props.onChange?.(newChoiceResponse);
  }
}
