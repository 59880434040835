import { useCompany } from "@inspecto/common";
import { Form, Input, Skeleton } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { DamagesRequiredFields } from "src/company";
import {
  ImmediateImageUploadersContextProvider,
  SearchableAsyncSelect,
  Select,
  SingleImageImmediateUploader,
  WithSaveButtonForm,
} from "src/components";
import { useValueFromQueryParams } from "src/hooks";
import { urls } from "src/urls";
import { getPlateNumber } from "src/utils";

import { backOfficeApi } from "../../api";
import { DamageStatusSelect } from "../../components/DamageStatusSelect";
import { useDamagesCustomVehicleFields } from "../../hooks/useDamagesCustomVehicleFields";
import { CreateDamage } from "../../models";
import { BackOfficeLayout } from "../BackOfficeLayout";

export function CreateEditDamageView({ isEditing }: { isEditing: boolean }) {
  const { t } = useTranslation("backoffice");
  const [form] = Form.useForm();
  const { damageId } = useParams<{ damageId?: string }>();
  const { allowStoringHighQualityImages } = useCompany();

  const selectedVehicleFromQueryParams = useValueFromQueryParams("vehicle");

  const history = useHistory();

  const [isLoadingExistingDamage, setIsLoadingExistingDamage] =
    useState(isEditing);
  const [existingDamage, setExistingDamage] = useState<
    | (Omit<CreateDamage, "vehicle"> & {
        vehicle: { value: string; label: string };
      })
    | null
  >(null);

  const selectedDamageCustomVehicleFieldId = Form.useWatch(
    "customVehicleField",
    form
  );

  const searchVehicles = useCallback(async (query: string) => {
    const vehicles = await backOfficeApi.getSimpleVehiclesList({
      tableParams: {
        pageNumber: 1,
        sortByField: "",
      },
      plateNumber: query,
      includeArchived: false,
    });

    return vehicles.results;
  }, []);

  useEffect(() => {
    if (!isEditing || !damageId) {
      return;
    }
    backOfficeApi.getDamage(damageId).then((damage) => {
      setExistingDamage({
        label: damage.label,
        photo: damage.photo,
        status: damage.status,
        vehicle: {
          value: damage.vehicleObject.id,
          label: getPlateNumber(damage.vehicleObject),
        },
        customVehicleField: damage.customVehicleFieldObject.id,
      });
      setIsLoadingExistingDamage(false);
    });
  }, [isEditing, damageId]);

  const {
    damageCustomVehicleFields,
    isLoadingDamagesCustomVehicleFields,
    damagesCustomVehicleFieldOptions,
  } = useDamagesCustomVehicleFields();

  const selectedDamageCustomVehicleField = useMemo(() => {
    return damageCustomVehicleFields.find(
      (field) => field.id === selectedDamageCustomVehicleFieldId
    );
  }, [damageCustomVehicleFields, selectedDamageCustomVehicleFieldId]);

  if (isLoadingExistingDamage) {
    return <Skeleton />;
  }

  return (
    <BackOfficeLayout
      breadcrumbs={[
        {
          label: t("damages.pageTitle"),
          url: urls.backOffice.damages.list(),
        },
      ]}
      pageTitle={isEditing ? t("damages.edit") : t("damages.create")}
    >
      <BackOfficeLayout.Content>
        <ImmediateImageUploadersContextProvider
          compressionPreset={
            allowStoringHighQualityImages ? "highQuality" : "standard"
          }
        >
          <WithSaveButtonForm
            form={form}
            saveCallback={async ({ vehicle, ...newDamage }) => {
              if (isEditing && damageId) {
                return await backOfficeApi.updateDamage(damageId, newDamage);
              } else {
                return await backOfficeApi.createDamage({
                  vehicle: vehicle.value,
                  ...newDamage,
                });
              }
            }}
            onSuccessfulSave={() => {
              history.replace(urls.backOffice.damages.list());
            }}
            initialValues={
              selectedVehicleFromQueryParams
                ? {
                    vehicle: JSON.parse(selectedVehicleFromQueryParams),
                  }
                : existingDamage || undefined
            }
          >
            <Form.Item
              label={t("damages.fieldNames.plateNumber")}
              name="vehicle"
              rules={[{ required: true }]}
            >
              <SearchableAsyncSelect
                disabled={isEditing}
                searchElements={searchVehicles}
                getLabel={getPlateNumber}
                valueKey="id"
                noMargin
                style={{ maxWidth: 200 }}
              />
            </Form.Item>

            {isLoadingDamagesCustomVehicleFields ? (
              <Skeleton />
            ) : (
              <Form.Item
                label={t("damages.fieldNames.customVehicleField")}
                name="customVehicleField"
                rules={[{ required: true }]}
              >
                <Select
                  options={damagesCustomVehicleFieldOptions}
                  disabled={isEditing}
                  style={{ maxWidth: 300 }}
                />
              </Form.Item>
            )}

            <Form.Item
              label={t("damages.fieldNames.status")}
              name="status"
              valuePropName="status"
              trigger="onStatusChange"
            >
              <DamageStatusSelect />
            </Form.Item>

            <Form.Item
              label={t("damages.fieldNames.photo")}
              name="photo"
              rules={[
                {
                  required:
                    !!selectedDamageCustomVehicleField?.damagesRequiredFields &&
                    [
                      DamagesRequiredFields.PHOTO,
                      DamagesRequiredFields.PHOTO_AND_LABEL,
                    ].includes(
                      selectedDamageCustomVehicleField?.damagesRequiredFields
                    ),
                },
              ]}
            >
              <SingleImageImmediateUploader uploaderId="edit-or-create-damage" />
            </Form.Item>

            <Form.Item
              label={t("damages.fieldNames.label")}
              name="label"
              rules={[
                {
                  max: 1024,
                  required:
                    !!selectedDamageCustomVehicleField?.damagesRequiredFields &&
                    [
                      DamagesRequiredFields.LABEL,
                      DamagesRequiredFields.PHOTO_AND_LABEL,
                    ].includes(
                      selectedDamageCustomVehicleField?.damagesRequiredFields
                    ),
                },
              ]}
            >
              <Input.TextArea style={{ maxWidth: 600 }} />
            </Form.Item>
          </WithSaveButtonForm>
        </ImmediateImageUploadersContextProvider>
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
