import { Form, Input, message, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { MaxWidth700WithSaveButtonForm } from "src/components";
import { UserLanguage, UserProtocolFillerLanguage } from "src/locales";
import { urls } from "src/urls";

import { backOfficeApi } from "../../api";
import { PinDisplay } from "../../components";
import { RetrieveCreateUpdateEmployee } from "../../models";

interface Props {
  currentEmployeeValue: RetrieveCreateUpdateEmployee | null;
  languageOptions: { label: JSX.Element; value: UserLanguage }[];
  protocolFillerLanguageOptions: {
    label: JSX.Element;
    value: UserProtocolFillerLanguage;
  }[];
  userRoleOptions: { label: string; value: string }[];
  availableCompanyDepartments: { label: string; value: string }[];
}

export function EmployeeForm(props: Props) {
  const { t } = useTranslation("backoffice");
  const history = useHistory();

  return (
    <MaxWidth700WithSaveButtonForm<RetrieveCreateUpdateEmployee, void>
      saveCallback={async (values) => {
        if (props.currentEmployeeValue?.id) {
          await backOfficeApi.updateEmployee(
            props.currentEmployeeValue?.id,
            values
          );
        } else {
          await backOfficeApi.createEmployee(values);
        }
      }}
      onSuccessfulSave={() => {
        history.replace(urls.backOffice.employees());
        if (props.currentEmployeeValue?.id) {
          message.success(t("employeeHasBeenSaved"));
        } else {
          message.success(t("employeeHasBeenCreated"));
        }
      }}
      initialValues={
        props.currentEmployeeValue ||
        ({
          language: "",
          protocolFillerLanguage: "",
          companyDepartments:
            props.availableCompanyDepartments.length === 1
              ? [props.availableCompanyDepartments[0]!.value]
              : [],
        } as Partial<RetrieveCreateUpdateEmployee>)
      }
    >
      <Form.Item
        label={t("userRole")}
        name="userRole"
        rules={[{ required: true }]}
      >
        <Select options={props.userRoleOptions} style={{ maxWidth: 280 }} />
      </Form.Item>
      {!!props.availableCompanyDepartments.length && (
        <Form.Item
          label={t("employeeFormView.companyDepartments")}
          name="companyDepartments"
          rules={[{ required: true }]}
        >
          <Select
            mode="multiple"
            options={props.availableCompanyDepartments}
            style={{ maxWidth: 280 }}
          />
        </Form.Item>
      )}
      <Form.Item
        label={t("firstName")}
        name="firstName"
        rules={[{ required: true }, { max: 64 }]}
      >
        <Input style={{ maxWidth: 280 }} />
      </Form.Item>
      <Form.Item
        label={t("lastName")}
        name="lastName"
        rules={[{ required: true }, { max: 64 }]}
      >
        <Input style={{ maxWidth: 280 }} />
      </Form.Item>
      <Form.Item
        label={t("email")}
        name="email"
        tooltip={t("employeeFormView.emailRequiredForBackOfficeAccess")}
      >
        <Input style={{ maxWidth: 400 }} />
      </Form.Item>
      <Form.Item
        label={t("phoneNumber")}
        name="phoneNumber"
        rules={[{ max: 64 }]}
      >
        <Input style={{ maxWidth: 240 }} />
      </Form.Item>
      {props.languageOptions.length > 2 && ( // 2 because 1 is allowed language and another one is "company default"
        <Form.Item
          label={t("employeeFormView.backofficeLanguage")}
          name="language"
        >
          <Select options={props.languageOptions} style={{ maxWidth: 480 }} />
        </Form.Item>
      )}
      {props.protocolFillerLanguageOptions.length > 2 && ( // 2 because 1 is allowed language and another one is "company default"
        <Form.Item
          label={t("employeeFormView.userProtocolFillerLanguage")}
          name="protocolFillerLanguage"
        >
          <Select
            options={props.protocolFillerLanguageOptions}
            virtual={false}
            style={{ maxWidth: 480 }}
          />
        </Form.Item>
      )}
      {props.currentEmployeeValue?.loginPin && (
        <Form.Item label={t("loginPin")}>
          <PinDisplay pin={props.currentEmployeeValue.loginPin} />
        </Form.Item>
      )}
    </MaxWidth700WithSaveButtonForm>
  );
}
