import { DefaultTheme } from "styled-components";

import * as antdVariables from "src/antdVariables";

const topBarHeight = 54;

export const myTheme: DefaultTheme = {
  antd: antdVariables,
  commonVariables: {
    inputLabelHeight: "24px",
    inputSpacing: "14px",
    backOfficeHeaderHeight: topBarHeight,
    protocolFillerHeaderHeight: topBarHeight,
  },
  zIndexes: {
    mainContent: 1,
    mobileHeader: 2,
    siderMenu: 3,
    devToolbar: 10,
  },
  colors: {
    whitePanelBorderColor: "#dadce0",
    protocolResponseBackground: "#fafafa",
    protocolResponseBorder: "#dcdcdc",
    background: "#fff",
    list: "#E7E7E7",
    entryBorder: "rgba(0, 0, 0, 0.38)",
    outline: "#bfbfbf",
    magnifyToolOpacity: "rgba(249, 249, 249, 0.75)",
    blurred: "rgba(0, 0, 0, 0.6)",
    selectedPicture: "rgba(0, 0, 0, 0.6)",
    requiredFieldAsterisk: "#ff4d4f",
    gray200: "#E5E5E5",
    gray300: "#D6D6D6",
    gray400: "#C1C1C1",
    gray600: "#797979",
    primary: {
      primary: antdVariables.primaryColor,
      hover: "#007a99",
      active: "#3cbcdd",
    },
    text: {
      light: "#F3F9FB",
      dark: "#3D3D3D",
      gray: "#A0A0A0",
    },
    icons: {
      hover: "#3D3D3D",
    },

    status: {
      ok: "#56c700",
      notOk: "#f91100",
    },
  },
};
