import { Alert } from "antd";

import {
  ChoiceResponse,
  DateFieldValue,
  DateMonthFieldComponent,
  emptyFieldValueForVerificationByType,
  FieldType,
  NumberFieldComponent,
  PhotosAndNoteComponent,
  PreventPhotoUploadFromFileBrowserContextProvider,
  TextFieldComponent,
} from "src/components";

import {
  ChildrenComponentProps,
  CustomFieldVerificationQuestionComponent,
  CustomFieldVerificationQuestionComponentProps,
  DamagesQuestionComponent,
  DateFieldWithSuggestedValues,
  MultiChoiceOrChoiceWithPhotosAndNoteComponent,
  NumberQuestionComponent,
  PhotoActionQuestionComponent,
  TextQuestionComponent,
} from "../components";
import { DateMonthQuestionComponent } from "../components/DateMonthQuestionComponent";
import { useProtocolFillerContext } from "../contexts";
import {
  ChoiceQuestion,
  CustomFieldUpdateQuestion,
  DateMonthQuestion,
  DateQuestion,
  MultiChoiceQuestion,
  NumberQuestion,
  PhotoActionQuestion,
  TextQuestion,
} from "../models";

interface DateQuestionViewData extends DateQuestion {
  suggestedValues: number[];
}

type QuestionViewDataSameAsBackendModel =
  | TextQuestion
  | NumberQuestion
  | DateMonthQuestion
  | PhotoActionQuestion
  | ChoiceQuestion
  | MultiChoiceQuestion
  | CustomFieldUpdateQuestion;

type QuestionViewData =
  | QuestionViewDataSameAsBackendModel
  | DateQuestionViewData;

interface QuestionViewProps {
  question: QuestionViewData;
}

export function QuestionView(props: QuestionViewProps): JSX.Element {
  const {
    setCustomFieldVerificationResponse,
    responses,
    setTextResponse,
    setNumberResponse,
    setDateResponse,
    setDateMonthResponse,
    setChoiceResponse,
  } = useProtocolFillerContext();

  switch (props.question.questionType) {
    case "text":
      return <TextQuestionComponent questionId={props.question.id} />;
    case "number":
      return <NumberQuestionComponent questionId={props.question.id} />;
    case "date":
      return (
        <DateFieldWithSuggestedValues
          suggestedValues={props.question.suggestedValues}
          onChange={(value: DateFieldValue) => {
            setDateResponse(props.question.id, value);
          }}
          value={responses.dateResponses[props.question.id]}
        />
      );
    case "date-month":
      return <DateMonthQuestionComponent questionId={props.question.id} />;
    case "photo-action":
      return (
        <PreventPhotoUploadFromFileBrowserContextProvider>
          <PhotoActionQuestionComponent question={props.question} />
        </PreventPhotoUploadFromFileBrowserContextProvider>
      );
    case "choice":
      const choiceResponse: ChoiceResponse | undefined =
        responses.choiceResponses[props.question.id];
      const baseChoiceResponse =
        choiceResponse || emptyFieldValueForVerificationByType.choice;

      return (
        <PreventPhotoUploadFromFileBrowserContextProvider>
          <MultiChoiceOrChoiceWithPhotosAndNoteComponent
            fileUploaderId={props.question.id}
            value={choiceResponse}
            noteRequirement={props.question.noteRequired}
            photoRequirement={props.question.photoRequired}
            choices={props.question.choices}
            onChange={(newResponse) =>
              setChoiceResponse(props.question.id, {
                ...baseChoiceResponse,
                ...newResponse,
              })
            }
          />
        </PreventPhotoUploadFromFileBrowserContextProvider>
      );
    case "multi-choice":
      const multiChoiceResponse: ChoiceResponse | undefined =
        responses.choiceResponses[props.question.id];
      const baseMultiChoiceResponse =
        multiChoiceResponse || emptyFieldValueForVerificationByType.choice;
      return (
        <PreventPhotoUploadFromFileBrowserContextProvider>
          <MultiChoiceOrChoiceWithPhotosAndNoteComponent
            isMultiChoice
            fileUploaderId={props.question.id}
            value={multiChoiceResponse}
            noteRequirement={props.question.noteRequired}
            photoRequirement={props.question.photoRequired}
            choices={props.question.choices}
            onChange={(newResponse) =>
              setChoiceResponse(props.question.id, {
                ...baseMultiChoiceResponse,
                ...newResponse,
              })
            }
          />
        </PreventPhotoUploadFromFileBrowserContextProvider>
      );
    case "custom-field-update":
      const commonProps: Pick<
        CustomFieldVerificationQuestionComponentProps<FieldType>,
        | "setCustomFieldVerificationResponse"
        | "customFieldVerificationResponse"
        | "previousCustomFieldValueFormatted"
        | "questionId"
      > = {
        setCustomFieldVerificationResponse: setCustomFieldVerificationResponse,
        customFieldVerificationResponse:
          responses.customFieldVerificationResponses[props.question.id],
        previousCustomFieldValueFormatted:
          props.question.customVehicleField.valueFormatted,
        questionId: props.question.id,
      };

      const customVehicleField = props.question.customVehicleField;

      if (customVehicleField.type === "damages") {
        return (
          <DamagesQuestionComponent
            isRequired={props.question.isRequired}
            questionId={props.question.id}
            customVehicleFieldId={customVehicleField.id}
            requiredFields={customVehicleField.damagesRequiredFields}
            damages={customVehicleField.damages}
          />
        );
      }

      if (!props.question.isCustomVehicleFieldVerification) {
        return (
          <QuestionView
            question={{
              ...props.question,
              choices:
                customVehicleField.type === "choice"
                  ? customVehicleField.choices
                  : [],
              photoRequired: customVehicleField.photoRequired,
              noteRequired: customVehicleField.noteRequired,
              questionType: customVehicleField.type,
              suggestedValues:
                customVehicleField.type === "date"
                  ? customVehicleField.suggestedValues
                  : [],
            }}
          />
        );
      }

      switch (customVehicleField.type) {
        case "text":
          return (
            <CustomFieldVerificationQuestionComponent
              {...commonProps}
              fieldType="text"
              setResponse={setTextResponse}
              response={responses.textResponses[props.question.id]}
              previousCustomFieldValue={customVehicleField.value}
              renderComponentForIncorrect={(renderProps) => {
                return (
                  <TextFieldComponent
                    autoFocus={renderProps.autoFocus}
                    value={renderProps.value}
                    onChange={
                      renderProps.setResponse
                        ? (event) =>
                            renderProps.setResponse?.(event.currentTarget.value)
                        : undefined
                    }
                  />
                );
              }}
            />
          );
        case "number":
          return (
            <CustomFieldVerificationQuestionComponent
              {...commonProps}
              fieldType="number"
              setResponse={setNumberResponse}
              response={responses.numberResponses[props.question.id]}
              previousCustomFieldValue={customVehicleField.value}
              renderComponentForIncorrect={(renderProps) => (
                <NumberFieldComponent
                  autoFocus={renderProps.autoFocus}
                  value={renderProps.value}
                  onChange={(data) => renderProps.setResponse(data)}
                />
              )}
            />
          );
        case "date":
          return (
            <CustomFieldVerificationQuestionComponent
              {...commonProps}
              fieldType="date"
              setResponse={setDateResponse}
              response={responses.dateResponses[props.question.id]}
              previousCustomFieldValue={customVehicleField.value}
              renderComponentForIncorrect={(renderProps) => (
                <DateFieldWithSuggestedValues
                  suggestedValues={customVehicleField.suggestedValues}
                  autoFocus={renderProps.autoFocus}
                  value={renderProps.value}
                  onChange={renderProps.setResponse}
                />
              )}
            />
          );
        case "date-month":
          return (
            <CustomFieldVerificationQuestionComponent
              {...commonProps}
              fieldType="date-month"
              setResponse={setDateMonthResponse}
              response={responses.dateMonthResponses[props.question.id]}
              previousCustomFieldValue={customVehicleField.value}
              renderComponentForIncorrect={(renderProps) => (
                <DateMonthFieldComponent
                  autoFocus={renderProps.autoFocus}
                  value={renderProps.value}
                  onChange={(value) =>
                    renderProps.setResponse?.(
                      typeof value === "undefined" ? null : value
                    )
                  }
                />
              )}
            />
          );
        case "choice":
          const response = responses.choiceResponses[props.question.id];
          const question = props.question;
          const baseChoiceResponse =
            response || emptyFieldValueForVerificationByType.choice;

          return (
            <CustomFieldVerificationQuestionComponent
              {...commonProps}
              fieldType="choice"
              setResponse={setChoiceResponse}
              choices={customVehicleField.choices}
              response={response}
              previousCustomFieldValue={{
                note: "",
                photos: [],
                selectedChoiceIds: customVehicleField.value
                  ? [customVehicleField.value]
                  : [],
              }}
              renderComponentForIncorrect={(renderProps) => (
                <PreventPhotoUploadFromFileBrowserContextProvider>
                  <MultiChoiceOrChoiceWithPhotosAndNoteComponent
                    fileUploaderId={question.id}
                    noteRequirement={customVehicleField.noteRequired}
                    photoRequirement={customVehicleField.photoRequired}
                    choices={renderProps.choices}
                    value={renderProps.value}
                    onChange={(data) =>
                      renderProps.setResponse({
                        ...baseChoiceResponse,
                        ...data,
                      })
                    }
                  />
                </PreventPhotoUploadFromFileBrowserContextProvider>
              )}
              renderComponentForCorrect={(
                renderProps: ChildrenComponentProps<"choice">
              ) => (
                <PreventPhotoUploadFromFileBrowserContextProvider>
                  <PhotosAndNoteComponent
                    fileUploaderId={question.id}
                    noteRequirement={customVehicleField.noteRequired}
                    photoRequirement={customVehicleField.photoRequired}
                    choices={renderProps.choices}
                    value={renderProps.value}
                    onChange={(data) =>
                      renderProps.setResponse({
                        ...baseChoiceResponse,
                        ...data,
                      })
                    }
                  />
                </PreventPhotoUploadFromFileBrowserContextProvider>
              )}
            />
          );
        default:
          return (
            <Alert
              type="error"
              message="Unexpected error occurred. Please contact the administrator."
            />
          );
      }
  }
}
