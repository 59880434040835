import * as fullStory from "@fullstory/browser";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import styled, { ThemeProvider } from "styled-components";

import "react-toastify/dist/ReactToastify.css";

import { AuthenticationManager } from "src/authentication";
import { ForgotPassword } from "src/authentication/ForgotPassword";
import { PasswordReset } from "src/authentication/PasswordReset";
import { ProtectedRoute } from "src/authentication/ProtectedRoute";
import { BackOffice } from "src/backOffice";
import {
  CeleryTaskProgressProvider,
  CommonStateProvider,
} from "src/backOffice/contexts";
import { CompanyProvider } from "src/company/CompanyProvider";
import { PageReloadModal } from "src/components";
import { DevToolbar } from "src/devToolbar";
import {
  useNewAppVersionAvailableHook,
  WebViewProvider,
  ServiceWorkerProvider,
} from "src/hooks";
import { LanguageProvider } from "src/locales";
import { ProtocolFillerRouter } from "src/protocolFiller";
import { ProtocolFillerApiConfig } from "src/protocolFiller/components";
import { LanguageConfig } from "src/protocolFiller/components/LanguageConfig";
import { ProtocolFillerSummaryPageManager } from "src/protocolFiller/contexts";
import { ReportingProvider } from "src/reporting";
import { myTheme } from "src/theme";
import { urls } from "src/urls";
import { isDevelopment } from "src/utils";
import { HTTPCodeErrorPage, LandingPage } from "src/views";
import "react-loading-skeleton/dist/skeleton.css";

if (!isDevelopment()) {
  Sentry.init({
    dsn: "https://8b111acfdd8043bea715c51706321b1e@o970631.ingest.sentry.io/5922060",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.0,
    ignoreErrors: [
      "ApiError",
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications.",
      "getBoundingClientRect",
    ],
  });
}
fullStory.init({
  orgId: "1521CE",
  devMode: isDevelopment(),
});
fullStory.shutdown();

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(customParseFormat);

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast-container {
  }
  .Toastify__toast {
  }
  .Toastify__toast--error {
    background-color: ${(props) => props.theme.colors.status.notOk};
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
    background-color: ${(props) => props.theme.colors.status.ok};
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;

export function App() {
  const { isNewAppVersionAvailable } = useNewAppVersionAvailableHook();

  return (
    <ServiceWorkerProvider isEnabled={!isDevelopment()}>
      <WebViewProvider>
        <LanguageProvider>
          <ThemeProvider theme={myTheme}>
            <CompanyProvider>
              <AuthenticationManager>
                <CeleryTaskProgressProvider>
                  <ReportingProvider>
                    <DevToolbar enabled={isDevelopment()}>
                      {!isDevelopment() && isNewAppVersionAvailable && (
                        <PageReloadModal />
                      )}
                      <Switch>
                        <ProtectedRoute path={urls.landingPage()} exact>
                          <LandingPage />
                        </ProtectedRoute>
                        <Route
                          path={urls.protocolFiller.summarySuccess()}
                          exact
                        >
                          <Redirect to={urls.protocolFiller.main()} />
                        </Route>
                        <Route path={urls.protocolFiller.main()}>
                          <LanguageConfig languageToUse="protocolFillerLanguage">
                            <ProtocolFillerSummaryPageManager>
                              <ProtectedRoute
                                path="*"
                                requiredPermission="canViewProtocolFiller"
                              >
                                <ProtocolFillerApiConfig>
                                  <ProtocolFillerRouter />
                                </ProtocolFillerApiConfig>
                              </ProtectedRoute>
                            </ProtocolFillerSummaryPageManager>
                          </LanguageConfig>
                        </Route>

                        <ProtectedRoute
                          path={urls.backOffice.main()}
                          requiredPermission="canViewBackoffice"
                        >
                          <LanguageConfig languageToUse="language">
                            <CommonStateProvider>
                              <BackOffice />
                            </CommonStateProvider>
                          </LanguageConfig>
                        </ProtectedRoute>
                        <Route path={urls.passwordReset()} exact>
                          <PasswordReset />
                        </Route>
                        <Route path={urls.forgotPassword()} exact>
                          <ForgotPassword />
                        </Route>
                        <Route path="*">
                          <HTTPCodeErrorPage
                            errorCode="404"
                            redirectUrl={urls.landingPage()}
                          />
                        </Route>
                      </Switch>
                    </DevToolbar>
                  </ReportingProvider>
                </CeleryTaskProgressProvider>
              </AuthenticationManager>
            </CompanyProvider>
            <StyledToastContainer />
          </ThemeProvider>
        </LanguageProvider>
      </WebViewProvider>
    </ServiceWorkerProvider>
  );
}

export default App;
