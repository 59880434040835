import * as Sentry from "@sentry/react";
import axios from "axios";
import { isObject } from "lodash";
import { useEffect, useState } from "react";

import { NEW_APP_VERSION_CHECK_INTERVAL } from "./config";

interface VersionResponse {
  fullHash: string;
}

export function useNewAppVersionAvailableHook(): {
  isNewAppVersionAvailable: boolean;
} {
  const [currentVersion, setCurrentVersion] = useState<string | "not-set">(
    "not-set"
  );
  const [isNewAppVersionAvailable, setIsNewAppVersionAvailable] =
    useState(false);

  useEffect(() => {
    async function checkIfNewVersionAvailable(): Promise<void> {
      if (isNewAppVersionAvailable) {
        return;
      }

      try {
        const { data } = await axios.get<unknown>("/version.json");
        if (!isVersionResponse(data)) {
          throw new Error(
            `Wrong version.json content: ${JSON.stringify(data)}`
          );
        }
        if (currentVersion === "not-set") {
          setCurrentVersion(data.fullHash);
        } else if (data.fullHash !== currentVersion) {
          setIsNewAppVersionAvailable(true);
        }
      } catch (e) {
        if (
          typeof e.message === "string" &&
          (e.message.includes("Network") ||
            e.message.includes("timeout") ||
            e.message.includes("Request failed with status code 504") ||
            e.message.includes("Request failed with status code 502") ||
            e.message.includes("Request aborted"))
        ) {
          // assume it's a Network Error and do nothing
          return;
        }

        Sentry.captureMessage("New App Version Available check failed", {
          level: Sentry.Severity.Error,
          extra: {
            body: e.body ? JSON.stringify(e.body) : null,
            message: e.message,
            error: JSON.stringify(e),
          },
        });
      }
    }

    const interval = setInterval(
      checkIfNewVersionAvailable,
      NEW_APP_VERSION_CHECK_INTERVAL
    );
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [currentVersion, isNewAppVersionAvailable]);

  return { isNewAppVersionAvailable };
}

function isVersionResponse(data: unknown): data is VersionResponse {
  return isObject(data) && data.hasOwnProperty("fullHash");
}
