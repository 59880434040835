import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { Button, Divider, Space, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { CSSProperties } from "styled-components";

import { ChargeEmployeeIconButton } from "src/components";
import { Icon } from "src/components/Icon";
import { myTheme } from "src/theme";

import { ResponseCommon } from "../models";
import { ChargedEmployeeComponent } from "./ChargedEmployeeComponent";

const statusIconHeight = 28;
const statusIconStyle: CSSProperties = {
  fontSize: statusIconHeight,
  display: "block",
  height: statusIconHeight,
};

const StyledDivider = styled(Divider)`
  top: 0;
  height: ${(props) => props.theme.antd.height.small};
`;

interface Props
  extends Pick<
    ResponseCommon,
    | "wasOk"
    | "okOverriddenAt"
    | "okOverriddenBy"
    | "comment"
    | "chargedEmployee"
  > {
  onStatusClick?: () => Promise<void>;
  onAddCommentClick?: () => void;
  onChargeEmployeeClick?: () => void;
  addCommentTooltipText: string;
  onApplyValueClick?: () => void;
  applyValueTooltipText: string;
}

export function ProtocolResponseActions(props: Props) {
  const { t } = useTranslation("backoffice");
  const [isChangingStatus, setIsChangingStatus] = useState(false);

  const overriddenDate = useMemo(() => {
    return dayjs(props.okOverriddenAt).format("DD.MM.YYYY");
  }, [props.okOverriddenAt]);
  const overriddenHour = useMemo(() => {
    return dayjs(props.okOverriddenAt).format("HH:mm");
  }, [props.okOverriddenAt]);

  const rightActionElements = [
    ...(props.onAddCommentClick
      ? [
          <Tooltip title={props.addCommentTooltipText}>
            <Button
              size="small"
              type="default"
              shape="circle"
              icon={<Icon icon="comment" size={16} />}
              onClick={props.onAddCommentClick}
              title={props.addCommentTooltipText}
            />
          </Tooltip>,
        ]
      : []),
    ...(props.onChargeEmployeeClick
      ? [<ChargeEmployeeIconButton onClick={props.onChargeEmployeeClick} />]
      : []),
    ...(props.onApplyValueClick
      ? [
          <Tooltip title={props.applyValueTooltipText}>
            <Button
              size="small"
              type="default"
              shape="circle"
              icon={<Icon icon="shareSquare" size={16} />}
              onClick={props.onApplyValueClick}
              data-testid="apply-value-button"
              title={props.applyValueTooltipText}
            />
          </Tooltip>,
        ]
      : []),
  ];

  return (
    <>
      <Space size="small" align="center">
        {props.wasOk ? (
          <CheckCircleTwoTone
            style={statusIconStyle}
            twoToneColor={myTheme.colors.status.ok}
          />
        ) : (
          <CloseCircleTwoTone
            style={statusIconStyle}
            twoToneColor={myTheme.colors.status.notOk}
          />
        )}
        {props.onStatusClick ? (
          <Tooltip title={t("protocolView.changeStatusToOpposite")}>
            <Button
              size="small"
              type="default"
              shape="circle"
              loading={isChangingStatus}
              icon={<Icon icon="exchangeAlt" size={16} />}
              onClick={async () => {
                setIsChangingStatus(true);
                await props.onStatusClick?.();
                setIsChangingStatus(false);
              }}
              title={t("protocolView.changeStatusToOpposite")}
            />
          </Tooltip>
        ) : null}
        {rightActionElements.length ? (
          <>
            <StyledDivider type="vertical" />
            {rightActionElements}
          </>
        ) : null}
      </Space>
      <Typography.Paragraph>
        <blockquote>
          {props.okOverriddenBy && (
            <Space align="start">
              <Icon icon="exchangeAlt" />
              <Typography.Text mark>
                {t("editedAtBy", {
                  date: overriddenDate,
                  hour: overriddenHour,
                  firstName: props.okOverriddenBy.firstName,
                  lastName: props.okOverriddenBy.lastName,
                })}
              </Typography.Text>
            </Space>
          )}
          <ChargedEmployeeComponent
            chargedEmployee={props.chargedEmployee}
            showStatusTag
          />

          {!!props.comment && (
            <div
              style={{
                paddingTop:
                  props.chargedEmployee || props.okOverriddenBy ? 16 : 0,
              }}
            >
              <Space>
                <strong>
                  {props.comment.author.firstName}{" "}
                  {props.comment.author.lastName}
                </strong>
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                  {dayjs(props.comment.date).format("DD.MM.YYYY, HH:mm")}
                </Typography.Text>
              </Space>
              <pre style={{ marginTop: 6, maxHeight: 130 }}>
                <div>{props.comment.text}</div>
              </pre>
            </div>
          )}
        </blockquote>
      </Typography.Paragraph>
    </>
  );
}
