import { AimOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Position,
  getCompanyProtocolFillerSelectors,
  useLocalPagination,
  useCompany,
  NoConnectionError,
} from "@inspecto/common";
import { Button, Form, Input, Modal, Space } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import { ProtocolPosition } from "src/backOffice/components";
import {
  FiltersAndPaginationForm,
  NoConnectionResultPage,
  OrDivider,
  RadioGroupOfTypeButton,
} from "src/components";
import { urls } from "src/urls";

import { protocolFillerApi } from "../api";
import { WhiteCard } from "../components";
import { ChooseVehicleViaQRCodeButton } from "../components/ChooseVehicleViaQRCodeButton";
import { PageLayout } from "../components/PageLayout";
import { protocolFillerLocalStorageContext } from "../contexts";
import { useVehicles } from "../hooks";
import { Vehicle } from "../models";

const PlateNumberAndButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledButtonNegativeMarginsAppearingAfterDelay = styled(Button)`
  margin: -9px -8px -9px 0;
  opacity: 0;
  animation: fade-in 0.3s ease-in-out 0.2s forwards;
 
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
`;

interface GetVehiclesParams {
  pageNumber: number;
  searchQuery: string;
}

interface Props {
  hasBackButton: boolean;
  showQRScan: boolean;
}

export function VehicleSelector(props: Props) {
  const { t } = useTranslation("protocolFiller");
  const company = useCompany();
  const [positionModalValue, setPositionModalValue] = useState<null | {
    position: Position;
    plateNumber: string;
  }>(null);
  const history = useHistory();
  const { vehicleTypeId } = useParams<{ vehicleTypeId: string }>();
  const [form] = Form.useForm();
  const pageNumber = Form.useWatch("pageNumber", form);
  const searchQuery = Form.useWatch("searchQuery", form);

  const [wasSubmittedWithMissingData, setWasSubmittedWithMissingData] =
    useState(false);

  const [vehicleId, setVehicleId] = useState("");
  const { clearStorage } = useContext(protocolFillerLocalStorageContext);

  const vehicleSelectors = useMemo(() => {
    return getCompanyProtocolFillerSelectors(company);
  }, [company]);

  const [vehiclePositions, setVehiclePositions] = useState<
    Record<Vehicle["id"], Position | "loading" | null>
  >({});

  useEffect(() => {
    clearStorage();
  }, [clearStorage]);

  useEffect(() => {
    setWasSubmittedWithMissingData(false);
  }, [vehicleId]);

  const { vehicles: allVehicles, loadingState, reloadVehicles } = useVehicles();

  const filteredVehicles = useMemo(() => {
    const lowerCasedSearchQuery = searchQuery ? searchQuery.toLowerCase() : "";
    if (loadingState !== "loaded" || !allVehicles || allVehicles.length === 0) {
      return [];
    }
    return allVehicles
      .filter((vehicle) => vehicle.vehicleType === vehicleTypeId)
      .filter((vehicle) =>
        vehicle.plateNumber.toLowerCase().includes(lowerCasedSearchQuery)
      );
  }, [loadingState, vehicleTypeId, allVehicles, searchQuery]);

  const { currentPage } = useLocalPagination(filteredVehicles, pageNumber);

  useEffect(() => {
    (async () => {
      // TODO Don't run for vehicles that we've already ran it for once (IN-798)
      let newVehiclePositions: typeof vehiclePositions = {};

      const currentPageVehicleIds = currentPage.map((vehicle) => vehicle.id);
      if (currentPageVehicleIds.length === 0) {
        return;
      }
      for (const vehicleId of currentPageVehicleIds) {
        newVehiclePositions[vehicleId] = "loading";
      }
      setVehiclePositions(newVehiclePositions);

      try {
        newVehiclePositions = await protocolFillerApi.getPositionsForVehicles(
          currentPageVehicleIds
        );
      } catch (e) {
        if (e instanceof NoConnectionError) {
          for (const vehicleId of currentPageVehicleIds) {
            newVehiclePositions[vehicleId] = null;
          }
          setVehiclePositions(newVehiclePositions);
          return;
        } else {
          throw e;
        }
      }

      for (const vehicleId of currentPageVehicleIds) {
        const newVehiclePosition = newVehiclePositions[vehicleId];
        if (newVehiclePosition) {
          newVehiclePositions[vehicleId] = newVehiclePosition;
        } else {
          newVehiclePositions[vehicleId] = null;
        }
      }

      setVehiclePositions(newVehiclePositions);
    })();
  }, [currentPage]);

  if (loadingState === "error") {
    return <NoConnectionResultPage onRetryClick={reloadVehicles} />;
  }
  return (
    <PageLayout
      onBackClick={
        props.hasBackButton
          ? () => history.push(urls.protocolFiller.main())
          : undefined
      }
      onNextClick={
        !!vehicleId
          ? () =>
              history.push(
                urls.protocolFiller.vehicleTypeAndVehicle(
                  vehicleTypeId,
                  vehicleId
                )
              )
          : () => setWasSubmittedWithMissingData(true)
      }
    >
      {props.showQRScan && vehicleSelectors.includes("qrCode") && (
        <WhiteCard
          innerKey="protocolType"
          title={t("qrCodes.title")}
          hasAsterisk
          errors={
            wasSubmittedWithMissingData
              ? [t("questionValidationErrors.requiredQuestion")]
              : []
          }
        >
          <ChooseVehicleViaQRCodeButton
            marginBottom={15}
            label={t("qrCodes.chooseVehicle")}
          />
        </WhiteCard>
      )}

      {vehicleSelectors.length > 1 && props.showQRScan && <OrDivider />}

      {vehicleSelectors.includes("plateNumber") && (
        <WhiteCard
          title={t("plateNumber")}
          innerKey="plateNumber"
          hasAsterisk
          errors={
            wasSubmittedWithMissingData
              ? [t("questionValidationErrors.requiredQuestion")]
              : []
          }
        >
          <FiltersAndPaginationForm<GetVehiclesParams>
            saveCallback={async () => {}}
            onValuesChange={(changedValues) => {
              if (!Object.keys(changedValues).includes("pageNumber")) {
                form.setFields([
                  {
                    name: "pageNumber",
                    value: 1,
                  },
                ]);
              }

              setVehicleId("");
            }}
            form={form}
            initialValues={{
              pageNumber: 1,
              searchQuery: "",
            }}
            isRetryableOnError
          >
            <Space direction="vertical" style={{ width: "100%" }} size="middle">
              <Form.Item noStyle name="searchQuery">
                <Input.Search
                  allowClear
                  autoComplete="off"
                  placeholder={t("plateNumber")}
                  loading={loadingState === "loading"}
                />
              </Form.Item>

              <Form.Item
                noStyle
                name="pageNumber"
                valuePropName="pageNumber"
                trigger="onPageNumberChange"
              >
                <RadioGroupOfTypeButton
                  mode={
                    loadingState === "loading" ? "verticalSkeleton" : "vertical"
                  }
                  total={filteredVehicles.length}
                  items={currentPage.map((vehicle) => {
                    const vehiclePosition =
                      vehicle.id in vehiclePositions
                        ? vehiclePositions[vehicle.id]
                        : null;
                    return {
                      label:
                        company.displayVehiclePositionInProtocolFillerVehicleSelector &&
                        vehiclePosition ? (
                          <PlateNumberAndButtonWrapper>
                            {vehicle.plateNumber}
                            <StyledButtonNegativeMarginsAppearingAfterDelay
                              icon={
                                vehiclePosition === "loading" ? (
                                  <LoadingOutlined style={{ fontSize: 16 }} />
                                ) : (
                                  <AimOutlined style={{ fontSize: 16 }} />
                                )
                              }
                              size="small"
                              title={t(
                                "vehiclePositionModal.locationOfPlateNumber",
                                {
                                  plateNumber: vehicle.plateNumber,
                                }
                              )}
                              onClick={() => {
                                if (
                                  !vehiclePosition ||
                                  vehiclePosition === "loading"
                                ) {
                                  return;
                                }
                                setPositionModalValue({
                                  position: vehiclePosition,
                                  plateNumber: vehicle.plateNumber,
                                });
                              }}
                            />
                          </PlateNumberAndButtonWrapper>
                        ) : (
                          vehicle.plateNumber
                        ),
                      value: vehicle.id,
                    };
                  })}
                  value={vehicleId}
                  onChange={(value) => setVehicleId(value == null ? "" : value)}
                />
              </Form.Item>
            </Space>
          </FiltersAndPaginationForm>
        </WhiteCard>
      )}
      {positionModalValue && (
        <Modal
          open
          title={t("vehiclePositionModal.locationOfPlateNumber", {
            plateNumber: positionModalValue.plateNumber,
          })}
          onCancel={() => setPositionModalValue(null)}
          okText={t("vehiclePositionModal.openInGoogleMaps")}
          okButtonProps={{
            href: `https://www.google.com/maps/search/?api=1&${new URLSearchParams(
              {
                query: [
                  positionModalValue.position.latitude,
                  positionModalValue.position.longitude,
                ].join(","),
              }
            ).toString()}`,
            target: "_blank",
          }}
        >
          <ProtocolPosition
            displayDate
            positionObject={positionModalValue.position}
          />
        </Modal>
      )}
    </PageLayout>
  );
}
