import { Button, Form, Popconfirm } from "antd";
import produce from "immer";
import { FormInstance } from "rc-field-form/es/interface";
import { useRef } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as uuid from "uuid";

import { MultiLanguageTextInput } from "src/company";
import {
  CreateUpdateChoiceFieldChoice,
  Icon,
  SwitchInput,
} from "src/components";
import { emptyTranslatedField } from "src/locales";

import { DefaultLanguageRequiredFormItem } from "./DefaultLanguageRequiredFormItem";

const ChoiceWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.outline};
  background: ${(props) => props.theme.colors.background};
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 3px;
`;

const AddChoice = styled(ChoiceWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  font-size: 20px;
  padding: 5px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.text.light};

  background-color: ${(props) => props.theme.colors.primary.primary};

  &:hover {
    background-color: ${(props) => props.theme.colors.primary.hover};
  }

  transition: background-color ease-in-out 0.2s;
`;

const ChoiceBottomBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

interface Props {
  getFieldValue: FormInstance["getFieldValue"];
  isPositiveOrRequiredLabel?: "isPositive" | "isRequired";
}

const CHOICES_FORM_ITEM_NAME = "choices";

export function DraggableChoicesListFormItem({
  isPositiveOrRequiredLabel = "isPositive",
  ...props
}: Props) {
  const { t } = useTranslation("backoffice");
  const addChoiceButton = useRef<HTMLDivElement | null>(null);

  return (
    <Droppable droppableId="choices" type="CHOICE">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          <Form.Item label={t("draggableChoicesList.label")}>
            <Form.List
              name={CHOICES_FORM_ITEM_NAME}
              rules={[
                {
                  validator: (rule, value) => {
                    if (!value || value.length < 1) {
                      return Promise.reject(
                        t("draggableChoicesList.youNeedAtLeastOneChoice")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => {
                    let frontendId = props.getFieldValue([
                      CHOICES_FORM_ITEM_NAME,
                      field.name,
                      "frontendId",
                    ]);
                    return (
                      <Draggable
                        draggableId={frontendId}
                        index={index}
                        key={frontendId}
                      >
                        {(provided1) => {
                          const optionLabel = t(
                            "draggableChoicesList.choiceLabel",
                            {
                              number: index + 1,
                            }
                          );
                          return (
                            <ChoiceWrapper
                              {...provided1.draggableProps}
                              {...provided1.dragHandleProps}
                              ref={provided1.innerRef}
                            >
                              <DefaultLanguageRequiredFormItem
                                label={optionLabel}
                                name={[field.name, "labelTranslations"]}
                                maxLength={128}
                              >
                                <MultiLanguageTextInput />
                              </DefaultLanguageRequiredFormItem>
                              <ChoiceBottomBar>
                                <Form.Item
                                  name={[field.name, "isPositiveOrRequired"]}
                                  noStyle
                                  trigger="onToggle"
                                  valuePropName="isChecked"
                                >
                                  <SwitchInput
                                    label={
                                      isPositiveOrRequiredLabel === "isRequired"
                                        ? t("builder.choiceFields.isRequired")
                                        : t("builder.choiceFields.isPositive")
                                    }
                                  />
                                </Form.Item>
                                <Popconfirm
                                  title={t(
                                    "draggableChoicesList.deleteChoiceConfirmation"
                                  )}
                                  onConfirm={() => remove(index)}
                                >
                                  <Button
                                    type="text"
                                    danger
                                    icon={<Icon icon="trashAlt" />}
                                    title={`${t(
                                      "tableActions.delete"
                                    )} - ${optionLabel}`}
                                  />
                                </Popconfirm>
                              </ChoiceBottomBar>
                            </ChoiceWrapper>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                  <AddChoice
                    ref={addChoiceButton}
                    data-test="add-choice"
                    onClick={() => {
                      const initialValues: CreateUpdateChoiceFieldChoice = {
                        frontendId: uuid.v4(),
                        id: null,
                        label: "",
                        labelTranslations: emptyTranslatedField,
                        isPositiveOrRequired: false,
                      };

                      add(initialValues);
                    }}
                  >
                    <Icon icon="plusCircle" />
                  </AddChoice>

                  <Form.ErrorList errors={errors} />
                </>
              )}
            </Form.List>
          </Form.Item>
        </div>
      )}
    </Droppable>
  );
}

function getOnChoicesReorderHandler(form: FormInstance) {
  return (sourceIndex: number, destinationIndex: number) => {
    const currentChoices: {}[] = form.getFieldValue(CHOICES_FORM_ITEM_NAME);
    form.setFieldsValue({
      choices: produce(currentChoices, (draft) => {
        const movingChoice = draft[sourceIndex];
        if (!movingChoice) {
          return;
        }
        draft.splice(sourceIndex, 1);
        draft.splice(destinationIndex, 0, movingChoice);
      }),
    });
  };
}

DraggableChoicesListFormItem.getOnChoicesReorderHandler =
  getOnChoicesReorderHandler;
