import { RequirementType } from "./models";

type ChoiceQuestionFieldRequirement = "required" | "optional" | false;

export function isChoiceQuestionFieldRequired(
  requirementType: RequirementType,
  isAnswerPositive: boolean,
  answerExists: boolean
): ChoiceQuestionFieldRequirement {
  switch (requirementType) {
    case RequirementType.Always:
      return answerExists ? "required" : false;
    case RequirementType.Optional:
      return "optional";
    case RequirementType.Never:
      return false;
    case RequirementType.OnNegative:
      return !isAnswerPositive && answerExists ? "required" : false;
    case RequirementType.OnPositive:
      return isAnswerPositive ? "required" : false;
  }
}

export interface IsChoiceResponsePositiveField {
  isMultiChoice: boolean;
  choices: { isPositiveOrRequired: boolean; id: string }[];
}

export function isChoiceResponsePositive(
  field: IsChoiceResponsePositiveField,
  selectedChoiceIds: string[]
): boolean {
  if (field.isMultiChoice) {
    const requiredChoices = field.choices.filter(
      (choice) => choice.isPositiveOrRequired
    );
    return requiredChoices.reduce<boolean>(
      (allPositiveSelected, currentElement) => {
        return (
          allPositiveSelected && selectedChoiceIds.includes(currentElement.id)
        );
      },
      true
    );
  } else {
    if (selectedChoiceIds.length === 0) {
      return false;
    }
    const selectedChoice = field.choices.filter((choice) =>
      selectedChoiceIds.includes(choice.id)
    )[0];

    if (!selectedChoice) {
      return false;
    }

    return selectedChoice.isPositiveOrRequired;
  }
}
