import {
  INSPECTO_LANDING_PAGE_TITLE,
  INSPECTO_LANDING_PAGE_URL,
  useCompany,
} from "@inspecto/common";
import { Grid } from "antd";
import { ReactNode } from "react";
import styled from "styled-components";

import { CompanyLogo } from "src/company";
import logo from "src/static/inspectoLogoBlue.svg";

const PoweredByLogo = styled.img`
  width: 80px;
  margin-left: 8px;
  vertical-align: text-top;
`;

const PoweredByWrapper = styled.div`
  margin-top: 60px;
  color: #606060;
  font-size: 12px;
  text-align: center;
  padding-bottom: 40px;
`;

const LogoWrapper = styled.div<{ isTabletSize: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${(props) => (props.isTabletSize ? "100px" : "50px")};
  padding-bottom: ${(props) => (props.isTabletSize ? "45px" : "30px")};
  margin-bottom: 30px;
  background-color: #fff;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.whitePanelBorderColor};
`;

interface Props {
  children: ReactNode;
  onLogoClick?: () => void;
}

export function FullScreenLogoPage(props: Props) {
  const company = useCompany();
  const breakpoint = Grid.useBreakpoint();

  const isTabletSize = breakpoint.md || false;

  return (
    <>
      <LogoWrapper isTabletSize={isTabletSize}>
        <CompanyLogo
          size={
            isTabletSize
              ? {
                  maxHeight: 80,
                  maxWidth: 172,
                }
              : {
                  maxHeight: 70,
                  maxWidth: 162,
                }
          }
          onClick={props.onLogoClick}
        />
      </LogoWrapper>
      {props.children}
      {company.logo ? (
        <PoweredByWrapper>
          Powered by
          <a
            target="_blank"
            href={INSPECTO_LANDING_PAGE_URL}
            title={INSPECTO_LANDING_PAGE_TITLE}
            rel="noreferrer"
          >
            <PoweredByLogo src={logo} alt="Inspecto" />
          </a>
        </PoweredByWrapper>
      ) : null}
    </>
  );
}
