import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  isSignatureWithSignature,
  isSignatureWithSigner,
} from "src/backOffice/models";
import { SearchableAsyncSelect } from "src/components";
import { SignatureInput } from "src/components/SignatureInput";

import { protocolFillerApi } from "../api";
import { WhiteCard } from "../components";
import { PageLayout, PageLayoutProgress } from "../components/PageLayout";
import { useProtocolFillerContext } from "../contexts";
import { TemplateSignaturePage } from "../models";
import { isSignaturePageValid } from "../selectors";
import { scrollToElement } from "../utils";

interface Props {
  signaturePage: TemplateSignaturePage;
  nextView: string;
  previousView: string;
  progress: PageLayoutProgress;
  onBeforeBack?: () => Promise<void>;
  onBeforeLogoClick?: () => Promise<void>;
}

export function SignaturePageView({
  onBeforeBack = () => Promise.resolve(),
  onBeforeLogoClick = () => Promise.resolve(),
  ...props
}: Props) {
  const { t } = useTranslation("translation");
  const { t: protocolFillerTranslation } = useTranslation("protocolFiller");
  const { setSignature, signatures } = useProtocolFillerContext();
  const history = useHistory();
  const [wasSubmittedWithMissingData, setWasSubmittedWithMissingData] =
    useState(false);
  const whiteCardRef = useRef<HTMLDivElement | null>(null);

  const { signerRoles } = props.signaturePage;

  const searchUsers = useCallback(
    async (query: string) => {
      return await protocolFillerApi.getEmployeesOptions(signerRoles, query);
    },

    [signerRoles]
  );

  const isRequired = props.signaturePage.isRequired;

  useEffect(() => {
    setWasSubmittedWithMissingData(false);
  }, [signatures]);

  useEffect(() => {
    if (wasSubmittedWithMissingData && whiteCardRef && whiteCardRef.current) {
      scrollToElement(whiteCardRef.current);
    }
  }, [wasSubmittedWithMissingData]);

  return (
    <PageLayout
      onLogoClick={async (navigateToLandingPage) => {
        try {
          await onBeforeLogoClick();
          navigateToLandingPage();
        } catch (e) {
          // Just do nothing
        }
      }}
      onNextClick={() =>
        isSignaturePageValid(props.signaturePage, signatures)
          ? history.push(props.nextView)
          : setWasSubmittedWithMissingData(true)
      }
      onBackClick={async () => {
        try {
          await onBeforeBack();
          history.push(props.previousView);
        } catch (e) {
          // Just do nothing
        }
      }}
      progress={props.progress}
    >
      <WhiteCard
        ref={whiteCardRef}
        title={props.signaturePage.label}
        hasAsterisk={isRequired}
        innerKey="signaturePage"
        errors={
          wasSubmittedWithMissingData
            ? [
                protocolFillerTranslation(
                  "questionValidationErrors.requiredQuestion"
                ),
              ]
            : []
        }
      >
        {isSignatureWithSigner(props.signaturePage.type) && (
          <SearchableAsyncSelect
            searchElements={searchUsers}
            placeholder={t("select")}
            valueKey="value"
            getLabel={(item) => item.label}
            value={signatures[props.signaturePage.id]?.signer}
            onChange={(signer) =>
              setSignature(props.signaturePage.id, { signer })
            }
          />
        )}
        {isSignatureWithSignature(props.signaturePage.type) && (
          <SignatureInput
            aria-label={props.signaturePage.label}
            onChange={(signature) =>
              setSignature(props.signaturePage.id, {
                signature,
              })
            }
            isRequired={isRequired}
            key={props.signaturePage.id}
            value={signatures[props.signaturePage.id]?.signature || null}
          />
        )}
      </WhiteCard>
    </PageLayout>
  );
}
