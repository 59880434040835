import { ReactNode } from "react";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { urls } from "src/urls";

import { FullScreenLogoPage } from "./FullScreenLogoPage";

const Content = styled.div`
  background: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.whitePanelBorderColor};
  border-radius: 4px;
  min-width: 300px;
  max-width: 90vw;
  width: 430px;
  color: ${(props) => props.theme.colors.text.dark};
  padding: 25px;
  margin: auto;
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
`;

interface Props {
  headerText?: string;
  children: ReactNode;
  isLoading?: boolean;
}

export function FullScreenCardLogoPage(props: Props) {
  const history = useHistory();
  return (
    <FullScreenLogoPage onLogoClick={() => history.push(urls.landingPage())}>
      <Content>
        {props.headerText && (
          <Header>
            {props.isLoading ? <Skeleton height={24} /> : props.headerText}
          </Header>
        )}
        {props.isLoading ? (
          <>
            <Skeleton height={50} count={2} style={{ margin: "10px 0" }} />
          </>
        ) : (
          props.children
        )}
      </Content>
    </FullScreenLogoPage>
  );
}
