import { Image, ImageProps } from "antd";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

interface Props extends Pick<ImageProps, "src"> {
  closeImage: () => void;
}

export function QuestionDescriptionImage(props: Props): JSX.Element | null {
  const history = useHistory();

  useEffect(() => {
    const unblock = history.block(() => {
      props.closeImage();
      return false; // Prevent navigation
    });

    return () => {
      unblock();
    };
  }, [history, props]);

  return (
    <Image
      src={props.src}
      style={{ display: "none" }}
      preview={{
        visible: true,
        src: props.src,
        onVisibleChange: props.closeImage,
      }}
    />
  );
}
