import { InfoCircleOutlined } from "@ant-design/icons";
import { message, Typography } from "antd";
import { createRef, RefObject, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { Box, useImmediateImageUploadersContext } from "src/components";

import { QuestionDescriptionImage, WhiteCard } from "../components";
import { PageLayout, PageLayoutProgress } from "../components/PageLayout";
import { useProtocolFillerContext } from "../contexts";
import { TemplatePage, QuestionResponseValidationError } from "../models";
import {
  getPageQuestionsResponseValidationErrors,
  isQuestionRequired,
} from "../selectors";
import { scrollToElement } from "../utils";
import { QuestionView } from "./QuestionView";

const PreserveNewLineAndSpaces = styled.div`
  white-space: pre-wrap;
`;

interface Props {
  page: TemplatePage;
  previousView: string;
  nextView: string;
  progress: PageLayoutProgress;
  onBeforeBack?: () => Promise<void>;
  onBeforeLogoClick?: () => Promise<void>;
}

export function PageView({
  onBeforeBack = () => Promise.resolve(),
  onBeforeLogoClick = () => Promise.resolve(),
  ...props
}: Props) {
  const { message: imageUploadersMessage } =
    useImmediateImageUploadersContext();
  const { t } = useTranslation("protocolFiller");
  const { responses, sendingStatus } = useProtocolFillerContext();
  const history = useHistory();

  const [descriptionImagePreview, setDescriptionImagePreview] =
    useState<string>("");

  const [questionResponseValidationError, setQuestionResponseValidationError] =
    useState<QuestionResponseValidationError | null>(null);
  const refs = useMemo(
    () =>
      props.page.questions.reduce<Record<string, RefObject<HTMLDivElement>>>(
        (prev, curr) => {
          return {
            ...prev,
            [curr.id]: createRef<HTMLDivElement>(),
          };
        },
        {}
      ),
    [props.page.questions]
  );

  useEffect(() => {
    setQuestionResponseValidationError(null);
  }, [responses]);

  useEffect(() => {
    if (questionResponseValidationError) {
      const foundRef = refs[questionResponseValidationError.questionId];
      if (foundRef && foundRef.current) {
        scrollToElement(foundRef.current);
      }
    }
  }, [questionResponseValidationError, refs]);

  return (
    <PageLayout
      overlaySpinnerText={
        sendingStatus === "sending" ? t("creatingProtocolMessage") : undefined
      }
      onLogoClick={async (navigateToLandingPage) => {
        try {
          await onBeforeLogoClick();
          navigateToLandingPage();
        } catch (e) {
          // Just do nothing
        }
      }}
      onNextClick={validateAndGoToNextPage}
      onBackClick={async () => {
        try {
          if (imageUploadersMessage) {
            return displayImageUploadersMessage();
          }
          await onBeforeBack();
          history.push(props.previousView);
        } catch (e) {
          // Just do nothing
        }
      }}
      progress={props.progress}
    >
      {props.page.questions.map((question) => (
        <WhiteCard
          key={question.id}
          errors={
            !!questionResponseValidationError &&
            questionResponseValidationError.questionId === question.id
              ? questionResponseValidationError.errors.map((error) =>
                  t(`questionValidationErrors.${error}`)
                )
              : []
          }
          ref={refs[question.id]}
          innerKey={question.id}
          title={question.label || "-"}
          hasAsterisk={isQuestionRequired(question)}
        >
          {!!question.description.length && (
            <Box mb={16}>
              <PreserveNewLineAndSpaces>
                {question.description}
              </PreserveNewLineAndSpaces>
            </Box>
          )}
          {!!question.descriptionImage && (
            <Box mb={16}>
              <Typography.Link
                onClick={() =>
                  setDescriptionImagePreview(question.descriptionImage || "")
                }
              >
                <InfoCircleOutlined style={{ paddingRight: 4 }} />
                {t("showGraphicalInstructionLink")}
              </Typography.Link>
            </Box>
          )}
          <QuestionView
            question={{
              ...question,
              ...{ suggestedValues: [] },
            }}
            key={question.id}
          />
        </WhiteCard>
      ))}
      {!!descriptionImagePreview && (
        <QuestionDescriptionImage
          src={descriptionImagePreview}
          closeImage={() => {
            setDescriptionImagePreview("");
          }}
        />
      )}
    </PageLayout>
  );

  function validateAndGoToNextPage(): void {
    const pageQuestionsResponseValidationErrors =
      getPageQuestionsResponseValidationErrors(props.page, responses);
    if (
      pageQuestionsResponseValidationErrors.length &&
      pageQuestionsResponseValidationErrors[0]
    ) {
      setQuestionResponseValidationError(
        pageQuestionsResponseValidationErrors[0]
      );
    } else if (imageUploadersMessage) {
      return displayImageUploadersMessage();
    } else {
      return history.push(props.nextView);
    }
    return;
  }

  function displayImageUploadersMessage() {
    message.error(imageUploadersMessage);
  }
}
