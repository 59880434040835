import { DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Image,
  Popconfirm,
  Row,
  Spin,
  Tag,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import emptyPhotoSquareImage from "src/static/empty-photo-square.svg";
import { StorageUploadedFile } from "src/storage";
import { myTheme } from "src/theme";

const DamageAndButtonWrapper = styled.div`
  display: flex;
`;

const DamageTextWrapper = styled.div`
  flex: 1 1 100%;
`;

const ImageWrapper = styled.div`
  position: relative;
  z-index: 0;
  &:hover {
    opacity: 0.8;
  }
`;

const StyledCenteredSpin = styled(Spin)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: rgba(190, 190, 190, 0.2);
`;

const ImageDisplayer = styled.div<{ url: string }>`
  padding-bottom: 100%;
  background-image: url("${(props) => props.url}");
  background-size: cover;
  background-position: center;
  cursor: pointer;
  line-height: 0;
  font-size: 0;
`;

const EmptyPhoto = styled.div`
  padding-bottom: 100%;
  background-image: url("${emptyPhotoSquareImage}");
  background-size: cover;
  background-color: rgba(190, 190, 190, 0.2);
  background-position: center;
  line-height: 0;
  font-size: 0;
`;

interface Props {
  damages: {
    label: string;
    photo: StorageUploadedFile | null;
    isNewAddition: boolean;
    key: string;
  }[];
  onDeleteClick?: (damageKey: string) => void;
}

export function ProtocolDamagesResponse(props: Props): JSX.Element {
  const { t } = useTranslation("protocolFiller");
  const [visibleImageKey, setVisibleImageKey] = useState<string | null>(null);

  return (
    <Row gutter={[0, 10]}>
      {props.damages.map((damage) => {
        return (
          <Col xs={24} key={damage.key}>
            <Row gutter={[16, 0]}>
              <Col xs={6}>
                {!!damage.photo ? (
                  <ImageWrapper>
                    <ImageDisplayer
                      url={damage.photo.thumbnailUrl}
                      onClick={() => setVisibleImageKey(damage.key)}
                    >
                      <Image
                        alt={damage.label}
                        key={damage.key}
                        style={{ display: "none" }}
                        preview={{
                          visible: visibleImageKey === damage.key,
                          src: damage.photo.fileUrl,
                          onVisibleChange: () => {
                            setVisibleImageKey(null);
                          },
                        }}
                      />
                    </ImageDisplayer>
                    <StyledCenteredSpin />
                  </ImageWrapper>
                ) : (
                  <EmptyPhoto />
                )}
              </Col>
              <Col xs={18}>
                <DamageAndButtonWrapper>
                  <DamageTextWrapper>
                    {damage.isNewAddition && (
                      <Tag color={myTheme.colors.status.notOk}>
                        {t("damages.newDamageTagText")}
                      </Tag>
                    )}
                    <Typography.Text strong={damage.isNewAddition}>
                      {damage.label}
                    </Typography.Text>
                  </DamageTextWrapper>

                  {props.onDeleteClick && (
                    <Popconfirm
                      title={t("damages.deleteConfirmation")}
                      onConfirm={() => props.onDeleteClick?.(damage.key)}
                    >
                      <Button icon={<DeleteOutlined />} danger type="text" />
                    </Popconfirm>
                  )}
                </DamageAndButtonWrapper>
              </Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
}
