import { StorageUploadedFile } from "src/storage";

import {
  CommonImmediateImageUploader,
  ImmediateImageUploaderProps,
} from "./CommonImmediateImageUploader";

export interface MultipleImagesImmediateUploaderProps
  extends Pick<ImmediateImageUploaderProps, "uploaderId"> {
  value?: StorageUploadedFile[];
  onChange?(images: StorageUploadedFile[]): void;
}

export function MultipleImagesImmediateUploader({
  ...props
}: MultipleImagesImmediateUploaderProps) {
  return <CommonImmediateImageUploader singleImageMode={false} {...props} />;
}
