import { Breadcrumb, PageHeader, Button, Grid, Skeleton } from "antd";
import { last } from "lodash";
import { CSSProperties, PropsWithChildren, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import { myTheme } from "src/theme";
import { urls } from "src/urls";

import {
  SiteContent,
  SiteHeaderAndContent,
  SiteLayout,
} from "./BackOffice.styles";

export const Loading = Symbol("loading");

export interface BreadcrumbInformation {
  label: string | typeof Loading;
  url?: string;
}

export interface BackOfficeLayoutProps {
  children: ReactNode;
  breadcrumbs?: BreadcrumbInformation[];
  pageTitle: string | typeof Loading;
  createPageUrl?: string;
  pageHeaderExtra?: ReactNode[];
  headerDescription?: JSX.Element;
  contentMinWidth?: number;
}

const textSkeletonStyles: CSSProperties = {
  verticalAlign: "middle",
  borderRadius: 4,
};

function BackOfficeLayoutFilters(props: PropsWithChildren<{}>) {
  return <div>{props.children}</div>;
}

function BreadcrumbItem(props: { breadcrumb: BreadcrumbInformation }) {
  const labelOrSkeleton =
    props.breadcrumb.label === Loading ? (
      <Skeleton.Button
        style={{
          ...textSkeletonStyles,
          width: 70,
          height: 14,
        }}
        active
      />
    ) : (
      props.breadcrumb.label
    );

  return (
    <Breadcrumb.Item>
      {props.breadcrumb.url ? (
        <Link to={props.breadcrumb.url}>{labelOrSkeleton}</Link>
      ) : (
        labelOrSkeleton
      )}
    </Breadcrumb.Item>
  );
}

// Suppresses ANT's warning:
// Breadcrumb: "Only accepts Breadcrumb.Item and Breadcrumb.Separator as it's children"
BreadcrumbItem.__ANT_BREADCRUMB_ITEM = true;

export function BackOfficeLayout({
  contentMinWidth = 860,
  pageHeaderExtra = [],
  ...props
}: BackOfficeLayoutProps): JSX.Element {
  const { t } = useTranslation("backoffice");
  const history = useHistory();

  const breakpoint = Grid.useBreakpoint();
  const isContentWithPadding = breakpoint.md;
  const breadcrumbHorizontalMargin = isContentWithPadding ? 0 : "12px";

  function getOnBackCallback(): (() => void) | undefined {
    if (props.breadcrumbs) {
      const lastBreadcrumb = last(props.breadcrumbs);

      if (lastBreadcrumb && lastBreadcrumb.url) {
        return () => lastBreadcrumb.url && history.push(lastBreadcrumb.url);
      }
    }
    return undefined;
  }

  return (
    <SiteLayout
      $minWidth={contentMinWidth}
      $horizontalPadding={isContentWithPadding ? 24 : 0}
    >
      <Breadcrumb
        style={{
          margin: `${
            myTheme.commonVariables.backOfficeHeaderHeight + 16
          }px ${breadcrumbHorizontalMargin} 16px ${breadcrumbHorizontalMargin}`,
        }}
      >
        <Breadcrumb.Item>
          <Link to={urls.landingPage()}>{t("home")}</Link>
        </Breadcrumb.Item>
        {props.breadcrumbs &&
          props.breadcrumbs.map((breadcrumb, index) => (
            <BreadcrumbItem key={index} breadcrumb={breadcrumb} />
          ))}

        <BreadcrumbItem
          breadcrumb={{
            label: props.pageTitle,
          }}
        />
      </Breadcrumb>
      <SiteHeaderAndContent>
        <PageHeader
          ghost={false}
          title={
            props.pageTitle === Loading ? (
              <Skeleton.Button
                style={{
                  ...textSkeletonStyles,
                  width: 200,
                  height: 30,
                }}
                active
              />
            ) : (
              props.pageTitle
            )
          }
          onBack={getOnBackCallback()}
          extra={[
            ...pageHeaderExtra,
            ...(props.createPageUrl
              ? [
                  <Button
                    key="create-page"
                    type="primary"
                    onClick={() =>
                      props.createPageUrl && history.push(props.createPageUrl)
                    }
                  >
                    + {t("add")}
                  </Button>,
                ]
              : []),
          ]}
        >
          {props.headerDescription}
        </PageHeader>
        {props.children}
      </SiteHeaderAndContent>
    </SiteLayout>
  );
}

BackOfficeLayout.Content = SiteContent;
BackOfficeLayout.Filters = BackOfficeLayoutFilters;
