import { Form } from "antd";
import { pick } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  ModalForm,
  ModalFormProps,
  RadioGroupOfTypeButton,
} from "src/components";
import { allRegularQuestionTypes } from "src/protocolFiller/models";

import { AvatarFieldType } from "../../../components";
import {
  ChoiceQuestion,
  DateMonthQuestion,
  DateQuestion,
  MultiChoiceQuestion,
  NumberQuestion,
  PhotoActionQuestion,
  QuestionCommon,
  RegularQuestion,
  TextQuestion,
} from "../../../models";
import {
  defaultIsRequired,
  defaultNoteRequired,
  defaultPhotoRequired,
} from "../defaults";

type IsRequiredProperty = Pick<TextQuestion, "isRequired">;

function isWithIsRequired(
  data: IsRequiredProperty | {}
): data is IsRequiredProperty {
  return data.hasOwnProperty("isRequired");
}

type ChoiceQuestionProperties = Pick<
  ChoiceQuestion,
  "photoRequired" | "noteRequired" | "choices"
>;

function isWithChoiceProperties(
  data: ChoiceQuestionProperties | {}
): data is ChoiceQuestionProperties {
  return (
    data.hasOwnProperty("photoRequired") &&
    data.hasOwnProperty("noteRequired") &&
    data.hasOwnProperty("choices")
  );
}

interface FormState {
  questionType: RegularQuestion["questionType"] | null;
}

export interface QuestionTypeSelectorModalProps
  extends Pick<ModalFormProps, "disableMotion"> {
  question: RegularQuestion;
  notAllowedQuestionTypes: RegularQuestion["questionType"][];
  closeModal: () => void;
  onNextClick: (data: RegularQuestion) => void;
}

export function QuestionTypeSelectorModal(
  props: QuestionTypeSelectorModalProps
) {
  const { t } = useTranslation("backoffice");
  const [form] = Form.useForm();

  const questionLabelByType: Record<RegularQuestion["questionType"], string> =
    useMemo(
      () => ({
        text: t("builder.fieldType.text"),
        number: t("builder.fieldType.number"),
        date: t("builder.fieldType.date"),
        "date-month": t("builder.fieldType.dateMonth"),
        choice: t("builder.fieldType.choice"),
        "multi-choice": t("builder.fieldType.multichoice"),
        "photo-action": t("builder.fieldType.photoAction"),
        damages: t("builder.fieldType.damages"),
      }),
      [t]
    );

  const questionTypeOptionValues: RegularQuestion["questionType"][] = useMemo(
    () => allRegularQuestionTypes,
    []
  );

  const questionTypeOptions = useMemo<
    { label: JSX.Element; value: RegularQuestion["questionType"] }[]
  >(
    () =>
      questionTypeOptionValues.map((questionType) => ({
        label: (
          <AvatarFieldType
            questionType={questionType}
            label={questionLabelByType[questionType]}
            isGrayedOut={props.notAllowedQuestionTypes.includes(questionType)}
          />
        ),
        value: questionType,
      })),
    [
      props.notAllowedQuestionTypes,
      questionLabelByType,
      questionTypeOptionValues,
    ]
  );

  const formInitialValues: FormState = {
    questionType: props.question.questionType,
  };

  return (
    <>
      <ModalForm<FormState>
        open
        width={775}
        disableMotion={props.disableMotion}
        form={form}
        saveCallback={async (formState) => {
          if (formState.questionType) {
            interface QuestionStateByType {
              text: TextQuestion;
              number: NumberQuestion;
              date: DateQuestion;
              "date-month": DateMonthQuestion;
              "photo-action": PhotoActionQuestion;
              "multi-choice": MultiChoiceQuestion;
              choice: ChoiceQuestion;
            }

            const common: QuestionCommon = {
              id: props.question.id,
              frontendId: props.question.frontendId,
              label: props.question.label,
              description: props.question.description,
              descriptionImage: props.question.descriptionImage,
            };

            const isRequiredProperty: IsRequiredProperty = {
              isRequired: isWithIsRequired(props.question)
                ? props.question.isRequired
                : defaultIsRequired,
            };

            const choiceQuestionProperties: ChoiceQuestionProperties =
              isWithChoiceProperties(props.question)
                ? pick(props.question, [
                    "noteRequired",
                    "photoRequired",
                    "choices",
                  ])
                : {
                    noteRequired: defaultNoteRequired,
                    photoRequired: defaultPhotoRequired,
                    choices: [],
                  };

            const initialQuestionStateByType: QuestionStateByType = {
              text: {
                ...common,
                ...isRequiredProperty,
                questionType: "text",
              },
              number: {
                ...common,
                ...isRequiredProperty,
                questionType: "number",
              },
              date: {
                ...common,
                ...isRequiredProperty,
                questionType: "date",
              },
              "date-month": {
                ...common,
                ...isRequiredProperty,
                questionType: "date-month",
              },
              "photo-action": {
                ...common,
                ...isRequiredProperty,
                questionType: "photo-action",
              },
              "multi-choice": {
                ...common,
                ...choiceQuestionProperties,
                questionType: "multi-choice",
              },
              choice: {
                ...common,
                ...choiceQuestionProperties,
                questionType: "choice",
              },
            };
            props.onNextClick({
              ...initialQuestionStateByType[formState.questionType],
            });
            return Promise.resolve();
          }
        }}
        closeModal={props.closeModal}
        formTitle={t("builder.createEditQuestion")}
        cancelButtonText={t("cancel")}
        confirmButtonText={t("builder.next")}
        initialValues={formInitialValues}
      >
        <Form.Item
          name="questionType"
          label={t("builder.questionFields.type")}
          rules={[
            { required: true },
            {
              validator: (rule, selectedQuestionType) => {
                if (
                  props.notAllowedQuestionTypes.includes(selectedQuestionType)
                ) {
                  const validationMessage = ["choice", "multi-choice"].includes(
                    selectedQuestionType
                  )
                    ? t(
                        "builder.choiceQuestionCreationInfo.cantSelectChoiceQuestionType"
                      )
                    : "This option can't be selected";
                  return Promise.reject(validationMessage);
                }
                return Promise.resolve();
              },
            },
          ]}
          style={{ marginBottom: 0 }}
        >
          <RadioGroupOfTypeButton
            mode="vertical"
            total={questionTypeOptions.length}
            items={questionTypeOptions}
          />
        </Form.Item>
      </ModalForm>
    </>
  );
}
