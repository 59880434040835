import { Layout } from "antd";
import styled from "styled-components";

import { myTheme } from "src/theme";

const { Content } = Layout;

export const sidebarWidth = 230;

export const siteContentHorizontalPaddings = 24;

export const LogoWrapper = styled.div`
  padding-top: 16px;
  padding-left: 20px;
  margin-bottom: 14px;
`;

export const LogoImg24 = styled.img`
  height: 24px;
`;

export const StyledSider = styled.div`
  background-color: #fff;
  width: ${sidebarWidth}px;
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: ${myTheme.zIndexes.siderMenu};
  border-right: 1px solid ${(props) => props.theme.colors.whitePanelBorderColor};
`;

export const SiteLayout = styled(Layout)(
  (props: { $minWidth: number; $horizontalPadding: number }) => `
    padding: 0 ${props.$horizontalPadding}px 48px;
    min-width: ${props.$minWidth}px;
    position: relative;
    z-index: ${myTheme.zIndexes.mainContent};
  `
);

export const SiteHeaderAndContent = styled.div`
  max-width: 1400px;
  background-color: #fff;
`;

export const SiteContent = styled(Content)`
  padding: 0 ${siteContentHorizontalPaddings}px 24px;
  margin: 0;
  min-height: 280px;
`;

export const LayoutHeaderFixed = styled.div<{ hasLeftPadding: boolean }>`
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-left: ${(props) => (props.hasLeftPadding ? sidebarWidth : 0)}px;
  z-index: ${myTheme.zIndexes.mobileHeader};
  height: ${myTheme.commonVariables.backOfficeHeaderHeight}px;
  background-color: #fff;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.whitePanelBorderColor};
`;

export const LayoutHeaderLeft = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0 10px 0 20px;
`;

export const LayoutHeaderRight = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 4px 16px 4px 20px;
`;
