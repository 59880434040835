import { isNil } from "lodash";

import { ChoiceResponse } from "src/components";
import {
  isChoiceQuestionFieldRequired,
  isChoiceResponsePositive,
} from "src/components";

import {
  ChoiceQuestion,
  CustomFieldUpdateQuestion,
  DamagesQuestion,
  isChoiceResponse,
  isRequiredModelQuestion,
  MultiChoiceQuestion,
  PhotoActionQuestion,
  QuestionResponseValidationError,
  Responses,
  responsesKeyByCustomFieldType,
  TemplatePage,
  TemplateQuestion,
  Signature,
  TemplateSignaturePage,
  TextQuestion,
  ValidationError,
} from "./models";

export function areTextResponsesValid(
  textQuestions: TextQuestion[],
  responses: Record<string, string>
): boolean {
  return textQuestions
    .filter((question) => question.isRequired)
    .reduce<boolean>(
      (areAllAnswered, textQuestion) =>
        areAllAnswered && !!responses[textQuestion.id],
      true
    );
}

export function arePhotoActionResponsesValid(
  photoActionQuestions: PhotoActionQuestion[],
  responses: Record<string, File | null>
): boolean {
  return photoActionQuestions
    .filter((question) => question.isRequired)
    .reduce<boolean>(
      (areAllAnswered, textQuestion) =>
        areAllAnswered && !!responses[textQuestion.id],
      true
    );
}

export function isTextResponseNotEmpty(
  questionId: string,
  textResponses: Responses["textResponses"]
): boolean {
  return !!textResponses[questionId];
}

export function isDamagesResponseNotEmpty(
  questionId: string,
  damagesResponses: Responses["damagesResponses"]
): boolean {
  const damagesResponse = damagesResponses[questionId];
  return !!damagesResponse && !!damagesResponse.length;
}

export function isNumberResponseNotEmpty(
  questionId: string,
  numberResponses: Responses["numberResponses"]
): boolean {
  return !isNil(numberResponses[questionId]);
}

export function isDateResponseNotEmpty(
  questionId: string,
  dateResponses: Responses["dateResponses"]
): boolean {
  return !!dateResponses[questionId];
}

export function isDateMonthResponseNotEmpty(
  questionId: string,
  dateMonthResponses: Responses["dateMonthResponses"]
): boolean {
  return !!dateMonthResponses[questionId];
}

export function isChoiceResponseNotEmpty(
  questionId: string,
  choiceResponses: Responses["choiceResponses"]
): boolean {
  const choiceResponse = choiceResponses[questionId];
  return choiceResponse ? !!choiceResponse.selectedChoiceIds.length : false;
}

export function getResponseValidationErrorsForCustomFieldVerification(
  question: CustomFieldUpdateQuestion,
  responses: Responses
): ValidationError[] {
  switch (question.customVehicleField.type) {
    case "text":
      return isTextResponseNotEmpty(question.id, responses.textResponses)
        ? []
        : [ValidationError.NEW_VALUE_CANNOT_BE_EMPTY];
    case "number":
      return isNumberResponseNotEmpty(question.id, responses.numberResponses)
        ? []
        : [ValidationError.NEW_VALUE_CANNOT_BE_EMPTY];
    case "date":
      return isDateResponseNotEmpty(question.id, responses.dateResponses)
        ? []
        : [ValidationError.NEW_VALUE_CANNOT_BE_EMPTY];
    case "date-month":
      return isDateMonthResponseNotEmpty(
        question.id,
        responses.dateMonthResponses
      )
        ? []
        : [ValidationError.NEW_VALUE_CANNOT_BE_EMPTY];
    case "choice":
      return isChoiceResponseNotEmpty(question.id, responses.choiceResponses)
        ? getChoiceResponseValidationErrors(
            {
              ...question,
              choices: question.customVehicleField.choices,
              photoRequired: question.customVehicleField.photoRequired,
              noteRequired: question.customVehicleField.noteRequired,
              questionType: question.customVehicleField.type,
            },
            question.isRequired,
            responses.choiceResponses[question.id]
          )
        : [ValidationError.NEW_VALUE_CANNOT_BE_EMPTY];
    default:
      return [];
  }
}

const alwaysRequiredQuestionTypes: TemplateQuestion["questionType"][] = [
  "choice",
  "multi-choice",
];

export function isQuestionRequired(question: TemplateQuestion): boolean {
  if (alwaysRequiredQuestionTypes.includes(question.questionType)) {
    return true;
  }

  return isRequiredModelQuestion(question) && question.isRequired;
}

export function getResponseValidationErrors(
  question: TemplateQuestion | DamagesQuestion,
  responses: Responses,
  isRequiredQuestion: boolean
): ValidationError[] {
  let isValid = false;
  switch (question.questionType) {
    case "text":
      isValid =
        !isRequiredQuestion ||
        isTextResponseNotEmpty(question.id, responses.textResponses);
      return isValid ? [] : [ValidationError.REQUIRED_QUESTION];
    case "number":
      isValid =
        !isRequiredQuestion ||
        isNumberResponseNotEmpty(question.id, responses.numberResponses);
      return isValid ? [] : [ValidationError.REQUIRED_QUESTION];
    case "date":
      isValid =
        !isRequiredQuestion ||
        isDateResponseNotEmpty(question.id, responses.dateResponses);
      return isValid ? [] : [ValidationError.REQUIRED_QUESTION];
    case "date-month":
      isValid =
        !isRequiredQuestion ||
        isDateMonthResponseNotEmpty(question.id, responses.dateMonthResponses);
      return isValid ? [] : [ValidationError.REQUIRED_QUESTION];
    case "photo-action":
      isValid =
        !isRequiredQuestion || !!responses.photoActionResponses[question.id];
      return isValid ? [] : [ValidationError.REQUIRED_QUESTION];
    case "choice":
      return getChoiceResponseValidationErrors(
        question,
        isRequiredQuestion,
        responses.choiceResponses[question.id]
      );
    case "multi-choice":
      return getChoiceResponseValidationErrors(
        question,
        isRequiredQuestion,
        responses.choiceResponses[question.id]
      );
    case "damages":
      isValid =
        !isRequiredQuestion ||
        isDamagesResponseNotEmpty(question.id, responses.damagesResponses);
      return isValid ? [] : [ValidationError.REQUIRED_QUESTION];
    case "custom-field-update":
      if (question.isCustomVehicleFieldVerification) {
        if (question.customVehicleField.type === "damages") {
          return [];
        }
        const fieldVerification =
          responses.customFieldVerificationResponses[question.id];
        const response =
          responses[
            responsesKeyByCustomFieldType[question.customVehicleField.type]
          ][question.id];

        if (
          typeof fieldVerification === "undefined" ||
          fieldVerification == null
        ) {
          return isRequiredQuestion ? [ValidationError.REQUIRED_QUESTION] : [];
        }
        if (fieldVerification === "incorrect") {
          const responseToCompare =
            response && isChoiceResponse(response)
              ? response.selectedChoiceIds.length
                ? response.selectedChoiceIds[0]
                : null
              : response;
          return responseToCompare === question.customVehicleField.value
            ? [ValidationError.NEW_VALUE_MUST_BE_DIFFERENT_THAN_CURRENT]
            : getResponseValidationErrorsForCustomFieldVerification(
                question,
                responses
              );
        }

        return question.customVehicleField.type === "choice"
          ? getChoiceResponseValidationErrors(
              {
                ...question,
                choices: question.customVehicleField.choices,
                photoRequired: question.customVehicleField.photoRequired,
                noteRequired: question.customVehicleField.noteRequired,
                questionType: question.customVehicleField.type,
              },
              question.isRequired,
              responses.choiceResponses[question.id]
            )
          : [];
      } else {
        if (question.customVehicleField.type === "choice") {
          return getResponseValidationErrors(
            {
              ...question,
              choices: question.customVehicleField.choices,
              photoRequired: question.customVehicleField.photoRequired,
              noteRequired: question.customVehicleField.noteRequired,
              questionType: question.customVehicleField.type,
            },
            responses,
            question.isRequired
          );
        }
        return getResponseValidationErrors(
          {
            ...question,
            questionType: question.customVehicleField.type,
          },
          responses,
          question.isRequired
        );
      }
  }
}

export function arePageResponsesValid(
  page: TemplatePage,
  responses: Responses
): boolean {
  return page.questions.reduce<boolean>(
    (previousValue, question) =>
      previousValue &&
      !getResponseValidationErrors(
        question,
        responses,
        isQuestionRequired(question)
      ).length,
    true
  );
}

export function getPageQuestionsResponseValidationErrors(
  page: TemplatePage,
  responses: Responses
): QuestionResponseValidationError[] {
  return page.questions.reduce<QuestionResponseValidationError[]>(
    (acc, question) => {
      const errors = getResponseValidationErrors(
        question,
        responses,
        isQuestionRequired(question)
      );
      if (!errors.length) {
        return acc;
      } else {
        return [...acc, { questionId: question.id, errors }];
      }
    },
    []
  );
}

export function isSignaturePageValid(
  signaturePage: TemplateSignaturePage,
  signatures: Record<string, Signature>
): boolean {
  switch (signaturePage.type) {
    case "signature_only":
      return (
        !signaturePage.isRequired || !!signatures[signaturePage.id]?.signature
      );
    case "signer_only":
      return (
        !signaturePage.isRequired ||
        !!signatures[signaturePage.id]?.signer?.value
      );
    case "signature_and_signer":
      return (
        !signaturePage.isRequired ||
        (!!signatures[signaturePage.id]?.signature &&
          !!signatures[signaturePage.id]?.signer?.value)
      );
  }
  return false;
}

export type PossibleRequiredChoiceField = keyof Pick<
  ChoiceResponse,
  "photos" | "note"
>;

const validationErrorByFieldName: Record<
  PossibleRequiredChoiceField,
  ValidationError
> = {
  photos: ValidationError.REQUIRED_PHOTOS,
  note: ValidationError.REQUIRED_NOTE,
};

export function getChoiceResponseValidationErrors(
  question: ChoiceQuestion | MultiChoiceQuestion,
  isRequired: boolean,
  response: ChoiceResponse | undefined = {
    photos: [],
    note: "",
    selectedChoiceIds: [],
  }
): ValidationError[] {
  if (
    !response.selectedChoiceIds.length &&
    isRequired &&
    question.questionType !== "multi-choice"
  ) {
    return [ValidationError.REQUIRED_QUESTION];
  }

  const answerExists =
    !!response.selectedChoiceIds.length ||
    question.questionType === "multi-choice";

  const requiredFields: PossibleRequiredChoiceField[] = [];
  const isAnswerPositive = isChoiceResponsePositive(
    {
      ...question,
      isMultiChoice: question.questionType === "multi-choice",
    },
    response.selectedChoiceIds
  );

  if (
    isChoiceQuestionFieldRequired(
      question.photoRequired,
      isAnswerPositive,
      answerExists
    ) === "required"
  ) {
    requiredFields.push("photos");
  }

  if (
    isChoiceQuestionFieldRequired(
      question.noteRequired,
      isAnswerPositive,
      answerExists
    ) === "required"
  ) {
    requiredFields.push("note");
  }

  return requiredFields.reduce<ValidationError[]>(
    (validationErrors, currentField) => {
      return !response[currentField]?.length
        ? validationErrors.concat(validationErrorByFieldName[currentField])
        : validationErrors;
    },
    []
  );
}
