import { Position as VehiclePosition } from "@inspecto/common";

import {
  ChoiceResponse,
  DateFieldValue,
  DateMonthFieldValue,
  PhotoActionFieldValue,
} from "src/components";
import { UserProtocolFillerLanguage } from "src/locales";
import { Api, api } from "src/utils/api";

import {
  Template,
  Vehicle,
  VehicleType,
  Damage,
  NumberResponse,
} from "./models";
import { Position } from "./utils/position";

export interface FillSignature {
  signature: string;
  signer: string | null;
}

export class ProtocolFillerApi {
  constructor(private api: Api) {}

  async getTemplateById(
    vehicleId: string,
    templateId: string
  ): Promise<Template> {
    return await this.api.get<Template>(
      `/protocol-filler/vehicles/${vehicleId}/templates/${templateId}/`
    );
  }

  async getTemplates(): Promise<Template[]> {
    return await this.api.get<Template[]>(`/protocol-filler/templates/`);
  }

  async getVehicleTypes() {
    return await this.api.get<VehicleType[]>("/protocol-filler/vehicle-types/");
  }

  async getVehicles() {
    return await this.api.get<Vehicle[]>("/protocol-filler/vehicles/");
  }

  async getPositionsForVehicles(vehicleIds: string[]) {
    return await this.api.get<Record<string, VehiclePosition>>(
      "/protocol-filler/vehicles/positions-for-vehicles/",
      {
        vehicle_ids: vehicleIds.join(","),
      }
    );
  }

  async fillTemplate(
    templateId: string,
    vehicleId: string,
    textResponses: Record<string, string>,
    numberResponses: Record<string, NumberResponse>,
    dateResponses: Record<string, DateFieldValue>,
    dateMonthResponses: Record<string, DateMonthFieldValue>,
    choiceResponses: Record<string, ChoiceResponse>,
    photoActionResponses: Record<string, PhotoActionFieldValue>,
    damagesResponses: Record<string, Damage[]>,
    signatures: Record<string, FillSignature>,
    createdInPosition: Position | null
  ): Promise<string> {
    const response = await this.api.post<{ protocolId: string }>(
      `/protocol-filler/vehicles/${vehicleId}/templates/${templateId}/fill/`,
      {
        textResponses,
        numberResponses,
        dateResponses,
        dateMonthResponses,
        choiceResponses,
        photoActionResponses,
        damagesResponses,
        signatures,
        createdInPosition,
      }
    );

    return response.protocolId;
  }

  async getVehicleByPlateNumber(vehiclePlateNumber: string) {
    const encodedVehiclePlateNumber = encodeURIComponent(vehiclePlateNumber);
    return await this.api.get<Vehicle>(
      `/protocol-filler/vehicles-by-plate-number/${encodedVehiclePlateNumber}/`
    );
  }

  async updateEmployee(
    userId: string,
    protocolFillerLanguage: UserProtocolFillerLanguage
  ) {
    return await this.api.patch(`/protocol-filler/employees/${userId}/`, {
      protocolFillerLanguage,
    });
  }

  async getEmployeesOptions(userRoles: string[], nameSearch: string) {
    const users = await this.api.getPaginatedList<
      { name: string; id: string }[]
    >(
      `/protocol-filler/employees/`,
      {
        pageNumber: 1,
      },
      {
        name: nameSearch,
        user_role__in: userRoles.join(","),
      }
    );

    return users.results.map((u) => ({ value: u.id, label: u.name }));
  }
}

export const protocolFillerApi = new ProtocolFillerApi(api);
